import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';
import { ExpandEvent } from '@progress/kendo-angular-treelist';
import { State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { SchemeItemsDataService } from './data.service';
import { SchemeItemEditModel } from './scheme-item-edit/scheme-item-edit-model';

@Component({
  selector: 'app-scheme-items',
  templateUrl: './scheme-items.component.html',
  styleUrls: ['./scheme-items.component.css']
})
export class SchemeItemsComponent implements OnInit {

  public data: Observable<any>;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 10
  };
  public formGroup: FormGroup;

  public schemeId = '';
  public schemeName = '';

  public editDataItem: SchemeItemEditModel;
  public isNew: boolean;

  private expandedIds: string[] = [];

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _dataService: SchemeItemsDataService,
    ) {
      this.createFormGroup = this.createFormGroup.bind(this);
  }

  ngOnInit() {
    const snapshot = this._activatedRoute.snapshot;

    this.schemeId = snapshot.params['id'];
    this._dataService.setCurrentSchemeId(this.schemeId);
    this._dataService.getSchemeName(this.schemeId).subscribe({ next: schemeName => this.schemeName = schemeName });

    this.data = this._dataService;
    this._dataService.read((data: any[]) => {
      this.expandedIds = data.map(x => x.id);
    });
  }

  copyToClip(ev) {
    const a = `%${ev.viewItem.data.alias}%`;
    // const a = ev.viewItem.data.alias;
    navigator.clipboard.writeText(a).then(() => {
      const all = document.querySelectorAll('.aa');
      for (let i = 0; i < all.length; i++) {
        if (all[i].classList.contains('sel')) {
          all[i].classList.remove('sel');
        }
      }

      if (ev.viewItem.data.alias) {
        document.querySelector(`.aa-${ev.viewItem.data.sort}`).classList.add('sel');
      }
    });
  }

  public addHandler({parent, sender}) {
    this.editDataItem = new SchemeItemEditModel();
    this.editDataItem.schemeId = this.schemeId;
    this.editDataItem.parentId = parent ? parent.id : null;
    this.isNew = true;

    if (parent) {
      sender.expand(parent);
    }
  }

  public editHandler({dataItem}) {
    this.editDataItem = dataItem;
    this.isNew = false;
  }

  public cancelHandler() {
    this.editDataItem = undefined;
  }

  public saveHandler(entity: SchemeItemEditModel) {
    this._dataService.save(entity, this.isNew);
    this.editDataItem = undefined;
  }

  public removeHandler({ dataItem }) {
      this._dataService.remove(dataItem.id);
  }

  public isExpanded = (dataItem: any): boolean => {
      return this.expandedIds.indexOf(dataItem.id) > -1;
  }

  public onCollapse(args: ExpandEvent): void {
      this.expandedIds = this.expandedIds.filter(id => id !== args.dataItem.id);
  }

  public onExpand(args: ExpandEvent): void {
      this.expandedIds.push(args.dataItem.id);
  }

  public onStateChange(state: State): void {
    this.gridState = state;
    this._dataService.read();
  }

  public createFormGroup({ isNew, dataItem }: CreateFormGroupArgs): any {
      const item = isNew ? {} : dataItem;

      this.formGroup = new FormGroup({
          'id': new FormControl(item.id),
          'parentId': new FormControl(item.parentId),
          'name': new FormControl(item.name, Validators.required),
          'controlTypeId': new FormControl(item.controlTypeId),
          'sort': new FormControl(item.sort)
      });

      return this.formGroup;
  }
}
