import { Injectable } from '@angular/core';

@Injectable()
export class SchemeItemEditParametersService {
  private data = [];
  private counter: number = this.data.length;

  public setItems(data: any[]) {
    this.data.length = 0;
    let idx = 0;
    data.forEach((item) => {
      this.data.push(item);
      item.tmpIntId = idx++;
    });
    this.counter = this.data.length;
  }

  public items(): any[] {
    return this.data;
  }

  public remove(item: any): void {
      const index = this.data.findIndex(({ tmpIntId }) => tmpIntId === item.tmpIntId);
      this.data.splice(index, 1);
  }

  public save(item: any, isNew: boolean): void {
      if (isNew) {
        item.tmpIntId = this.counter++;
        this.data.splice(0, 0, item);
      } else {
          Object.assign(
              this.data.find(({ tmpIntId }) => tmpIntId === item.tmpIntId),
              item
          );
      }
  }
}
