import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    State,
    toDataSourceRequestString,
} from '@progress/kendo-data-query';

import { AppConfig } from 'src/shared/app.config';
import { AnketModel } from './anket-model';
import { AnketFilterConsts } from '../consts/anket-filter-consts';
import { AnketDefaultDataInputModel } from './ancet-edit-inject/anket-default-data-input-model';
import { ForUiCombobox } from '../models/ForUiCombobox';

const CREATE_ACTION = 'add';
const UPDATE_ACTION = 'save';
const REMOVE_ACTION = 'delete';

@Injectable()
export class AnketsDataService extends BehaviorSubject<any[]> {

private addOnHeaders: HttpHeaders = new HttpHeaders({
  // 'Authorization': 'Bearer BQDWZC2Y_iSV3rfmAhv-QxDpmhhJbbmgCnspy7HpIZPX5CbJ74D4Xl4aOyXLUL4smF2gZ_V3wiSXLxdLFPY',
  'Content-Type': 'application/json'
});

constructor(private http: HttpClient) {
  super([]);
}

private data: any[] = [];
public state: State = {
  skip: 0,
  take: 20,
  filter: { filters: [], logic: 'or' },
  group: [],
  sort: []
};

private currentSchemeId: string = null;

public setCurrentSchemeId(schemeId: string) {
  this.currentSchemeId = schemeId;
}

public get(doctorId: number, patientId: number, schemeId: string, schemeAlias: string, id: string): Observable<AnketModel> {
  const url = `${AppConfig.resourceApiUrl}/api/anket/get-or-default`;
  const data = new AnketDefaultDataInputModel();

  if (id) {
    data.key = id;
  } else if (schemeId) {
    data.schemeId = schemeId;
  } else if (schemeAlias) {
    data.schemeAlias = schemeAlias;
  }

  data.doctorId = doctorId;
  data.patientId = patientId;

  return this.http.post(url, data, { headers: this.addOnHeaders }).pipe(
      map(res => <AnketModel>res)
    );
}

public importScheme(body): Observable<string> {
  const url = `${AppConfig.resourceApiUrl}/api/wsdoctor/import-scheme`;
  return this.http.post(url, body, { headers: this.addOnHeaders }).pipe(
    map(res => <string>res)
  );
}

public exportScheme(schemeId): Observable<string> {
  const url = `${AppConfig.resourceApiUrl}/api/wsdoctor/export-scheme`;
  return this.http.post(url, schemeId, { headers: this.addOnHeaders }).pipe(
    map(res => <string>res)
  );
}

public exportAnket(anketId): Observable<string> {
  const url = `${AppConfig.resourceApiUrl}/api/wsdoctor/export-anket`;
  return this.http.post(url, anketId, { headers: this.addOnHeaders }).pipe(
    map(res => <string>res)
  );
}

public getCustomComboboxData(apiDataSource: string, p = 0): Observable<any[]> {
  let pagesPar = p ? `?p=${p}` : '';
  const url = `${AppConfig.resourceApiUrl}/api/${apiDataSource}${pagesPar}`;
  return this.http.get(url).pipe(
    map(res => <any>res)
  );
}

public getStaffList(): Observable<any[]> {
  const url = `${AppConfig.resourceApiUrl}/api/wsdoctor/get-med-staffs`;
  return this.http.get(url).pipe(
    map(res => <any>res)
  );
}

public getCardsNumbers(patientId): Observable<any[]> {
  const url = `${AppConfig.resourceApiUrl}/api/wsdoctor/get-patient-cards-numbers?patientId=${patientId}`;
  return this.http.get(url).pipe(
    map(res => <any>res)
  );
}

public getMkb10List(p): Observable<ForUiCombobox[]> {
  const url = `${AppConfig.resourceApiUrl}/api/wsdoctor/get-mkb10-emias?p=${p}`;
  return this.http.get(url).pipe(
    map(res => <ForUiCombobox[]>res)
  );
}

public getMedStaffFilial(doctorId): Observable<any> {
  const url = `${AppConfig.resourceApiUrl}/api/wsdoctor/get-medstaff-filial?doctorId=${doctorId}`;
  return this.http.get(url).pipe(
    map(res => (<any>res).filialName)
  );
}

public getPodrazdeleniyaList(): Observable<ForUiCombobox[]> {
  const url = `${AppConfig.resourceApiUrl}/api/wsdoctor/get-podrazdeleniya-list`;
  return this.http.get(url).pipe(
    map(res => <ForUiCombobox[]>res)
  );
}

public getMnnList(p): Observable<ForUiCombobox[]> {
  const url = `${AppConfig.resourceApiUrl}/api/wsdoctor/get-mnn-list?p=${p}`;
  return this.http.get(url).pipe(
    map(res => <ForUiCombobox[]>res)
  );
}

public read() {
    if (this.data.length) {
        return super.next(this.data);
    }

    this.fetch()
        .subscribe(data => {
            super.next(data);
        });
}

public getAutoSave(userId: number, patientId: number, schemeId: string, anketId: string): Observable<string> {
  const url = `${AppConfig.resourceApiUrl}/api/anket/get-auto-save-data`;
  return this.http.post(url, { userId, patientId, schemeId, anketId }, { headers: this.addOnHeaders }).pipe(
    map(res => <string>res)
  );
}

public sendToRegistr(data: any): Observable<any> {
  const url = `${AppConfig.resourceApiUrl}/api/anket/send-to-registr`;
  return this.http.post(url, data, { headers: this.addOnHeaders }).pipe(
    map(res => <any>res)
  );
}

public changeState(anketId: string, userId: number, newState: number): Observable<any> {
  const url = `${AppConfig.resourceApiUrl}/api/anket/change-state`;
  return this.http.post(url, { anketId, userId, newState }, { headers: this.addOnHeaders }).pipe(
    map(res => <any>res)
  );
}

public autoSave(userId: number, patientId: number, schemeId: string, anketId: string, data: string) {
  const url = `${AppConfig.resourceApiUrl}/api/anket/auto-save`;
  this.http.post(url, { userId, patientId, schemeId, anketId, data }, { headers: this.addOnHeaders }).subscribe();
}

public save(anket: AnketModel, saveCopyAsNew: boolean = false) {
    const action = !anket.id ? CREATE_ACTION : UPDATE_ACTION;
    const copyAnket = Object.assign({}, anket);
    copyAnket.schemeVersion = null;
    copyAnket.patient = null;
    copyAnket.doctor = null;
    copyAnket.anketItems.forEach(element => {
      element.schemeItem = null;
    });

    if (saveCopyAsNew) {
      copyAnket.id = null;
    }

    return this.fetch(action, copyAnket);
}

public remove(data: any) {
  this.reset();

  this.fetch(REMOVE_ACTION, data)
    .subscribe(() => this.read(), () => this.read());
}

public resetItem(dataItem: any) {
  if (!dataItem) {
    return;
  }

  const originalDataItem = this.data.find(item => item.ProductID === dataItem.ProductID);

  Object.assign(originalDataItem, dataItem);

  super.next(this.data);
}

private reset() {
    this.data = [];
}

private fetch(action: string = '', data?: any): Observable<any> {
  switch (action) {
    case '': {
        const url = `${AppConfig.resourceApiUrl}/api/anket/page`;
        return this.http.post(url, this.getBody(this.currentSchemeId), { headers: this.addOnHeaders }).pipe(
            map(res => <any[]>res)
          );
    }
    case 'add': {
      const url = `${AppConfig.resourceApiUrl}/api/anket/add`;
      return this.http.post(url, data, { headers: this.addOnHeaders }).pipe(
          map(res => (<any>res).result)
        );
    }
    case 'save': {
      const url = `${AppConfig.resourceApiUrl}/api/anket/save`;
      return this.http.post(url, data, { headers: this.addOnHeaders }).pipe(
          map(res => (<any>res).result)
        );
    }
    case 'delete': {
      const url = `${AppConfig.resourceApiUrl}/api/anket/delete?id=${data}`;
      return this.http.post(url, null, { headers: this.addOnHeaders }).pipe(
          map(res => <any>res)
        );
    }
  }
}

private getBody(schemeId: string): any {
  return {
    filters: [ { id: AnketFilterConsts.filterBySchemeId, parameters: { value: schemeId } } ]
  };
}
}
