import { Component, Input, OnInit } from '@angular/core';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { ForUiCombobox } from '../models/ForUiCombobox';

@Component({
  selector: 'app-mnn',
  templateUrl: './mnn.component.html',
  styleUrls: ['./mnn.component.css']
})
export class MnnComponent implements OnInit {

  public virtual: any = {
    itemHeight: 30
  };

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  @Input() public disabled: boolean;
  @Input() public model: any[] = [];
  @Input() public allMnn: ForUiCombobox[] = [];

  public data: any[] = [];

  constructor() { }

  ngOnInit() {
    this.data.splice(0, this.data.length);
    if (this.model) {
      this.model.forEach(element => {
        this.data.push(element);
      });
    }
  }

  valueChanged() {
    this.model.splice(0, this.model.length);
    this.data.forEach(element => {
      this.model.push(element);
    });
  }

}
