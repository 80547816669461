import { Component, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExpandEvent } from '@progress/kendo-angular-treelist';
import { ForUiCombobox } from 'src/app/models/ForUiCombobox';
import { SchemeEditModel } from 'src/app/schemas/scheme-edit-model';
import { SchemeItemEditModel } from 'src/app/schemas/scheme-items/scheme-item-edit/scheme-item-edit-model';
import { AnketItemModel } from '../anket-item-model';
import { AnketModel } from '../anket-model';
import { AnketsDataService } from '../data.service';
import { ValueAddOnModel } from '../value-addon-model';

@Component({
  selector: 'app-ancet-edit-inject',
  templateUrl: './ancet-edit-inject.component.html',
  styleUrls: ['./ancet-edit-inject.component.scss']
})
export class AncetEditInjectComponent  implements OnInit, OnDestroy {

  public san: string;
  public anketId: string;
  public schemeId: string;
  public schemeAlias: string;
  public schemeName: string;
  public isNew: boolean;

  public userId: number;
  public doctorId: number;
  public patientId: number;

  public anket: AnketModel;

  public staffList: Array<any> = [];
  public cardsNumbers: Array<any> = [];

  public test1: number;

  public isDropDownListOpen: boolean;

  public autoSaveDialogActive = false;
  public autoSaveDialogMessage: string;
  public autoSaveDialogData: string;

  public fieldTemplateOpenedDataItem: any;
  public fieldTemplateOpenedEntityId: string;

  private rightPaneScrollLocker = 0;

  private autoSaveIntervalLock = false;
  private autoSaveIntervalToken = undefined;
  private autoSaveAnketHash = undefined;


  public virtual: any = {
    itemHeight: 30
  };

  public allMkb10Emiases: ForUiCombobox[] = [];
  public allMnn: ForUiCombobox[] = [];

  @Output() hideAppNav = new EventEmitter();

  public get scheme(): SchemeEditModel {
    return this.anket && this.anket.schemeVersion
      ? this.anket.schemeVersion.scheme
      : null;
  }

  public get anketItemForm(): AnketItemModel {
    return this.selectedIds.length > 0
      ? this.anket.anketItems.filter(x => x.schemeItemId === this.selectedIds[0])[0]
      : null;
  }

  public selectedIds: string[] = [];
  public expandedIds: string[] = [];

  private parsAnketScrollSubscription: any;

  public get items(): SchemeItemEditModel[] {
    return this.anket && this.anket.schemeVersion && this.anket.schemeVersion.scheme
      ? this.anket.schemeVersion.scheme.schemeItems.sort((a, b) => a.sort - b.sort)
      : [];
  }

  public get leftItems(): SchemeItemEditModel[] {
    return this.items.filter(x => x.schemeItemName !== '_');
  }

  public get disabled(): boolean {
    return !this.anket || this.anket.state !== 0;
  }

  constructor(
    private anketsDataService: AnketsDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
  ) {
    // elRef.nativeElement.ownerDocument.body.style.minHeight = 'initial';
    // elRef.nativeElement.ownerDocument.body.style.height = 'initial';
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(
      (queryParam: any) => {
          this.san = queryParam['san'];
          this.anketId = queryParam['id'];
          this.schemeId = queryParam['schemeId'];
          this.schemeAlias = queryParam['schemeAlias'];
          this.patientId = parseInt(queryParam['patientId'], 10);
          this.doctorId = parseInt(queryParam['doctorId'], 10);
          this.userId = parseInt(queryParam['userId'], 10);
          this.isNew = !this.anketId;
      }
    );

    this.anketsDataService.get(this.doctorId, this.patientId, this.schemeId, this.schemeAlias, this.anketId).subscribe({ next: anket => {
      this.anket = anket;
      this.schemeId = anket.schemeVersion.schemeId;
      this.anketAddOnValueFill(anket.anketItems);
      this.expandedIds = this.items.map(x => x.id);

      anket.anketItems.forEach(anketItem => {
        anketItem.schemeItem.anketItem = anketItem;

        if (anketItem.schemeItem.anyParams && anketItem.schemeItem.anyParams.addOnJson && anketItem.schemeItem.anyParams.addOnJson.ifThenVisible) {
          this.changeIfThenVisibleInit(anketItem, anketItem.schemeItem.anyParams.addOnJson.ifThenVisible);
        }
      });

      if (window.parent) {
        window.parent.postMessage({ target: 'anket-data-loaded', key: 'anketData', value: this.anket }, '*');
      }

      this.anketsDataService.getStaffList().subscribe(
        (data) => {
          this.staffList = data.map(x => {
            return { text: x.text, value: x.id.toString(), name: x.name };
          });
        });

      this.anketsDataService.getCardsNumbers(this.patientId).subscribe(
        (data) => {
          this.cardsNumbers = data.map(x => {
            return { text: x.text, value: x.id.toString() };
          });
        });

      this.fillMkb10Rec(1);
      this.fillMnnRec(1);

      this.anketsDataService.getAutoSave(this.userId, this.patientId, this.schemeId, this.anketId).subscribe({ next: autoSaveData => {
        if (autoSaveData) {
          this.autoSaveDialogOpen(autoSaveData);
        }
      }});

      const that = this;
      this.autoSaveAnketHash = this.getAutoSaveAnketHash(this);
      this.autoSaveIntervalToken = setInterval(function() { that.autoSave(that); }, 1000);
      
      setInterval(function() { that.anketInterval(that); }, 1000);

      if (this.san === '1') {
        this.saveCopyAsNew(() => { /* console.log('saved'); */ });
      }
    }});

    (<any>window).inner = (<any>window).inner || {};
    (<any>window).inner.publicFunc2 = this.publicFunc2.bind(this);
    (<any>window).inner.publicFunc3 = this.publicFunc3.bind(this);
    (<any>window).inner.publicFuncChangeAnketState = this.publicFuncChangeAnketState.bind(this);
    (<any>window).inner.publicFuncSignAnket = this.publicFuncSignAnket.bind(this);
  }

  anketInterval(ths) {
    for (const selector in ths._changeIfThenVisible) {
      const docEl = document.querySelector(selector);
      if (docEl) {
        if (this._changeIfThenVisible[selector]) {
          delete this._changeIfThenVisible[selector];
        }
        (<any>docEl).style.display = 'none';
      }
    }
  }

  fillMkb10Rec(p: number) {
    this.anketsDataService.getMkb10List(p).subscribe(
      (data) => {
        if (data.length < 1) {
          return;
        }
        this.allMkb10Emiases = this.allMkb10Emiases.concat(data);
        this.fillMkb10Rec(++p);
      });
  }

  fillMnnRec(p: number) {
    this.anketsDataService.getMnnList(p).subscribe(
      (data) => {
        if (data.length < 1) {
          return;
        }
        this.allMnn = this.allMnn.concat(data);
        this.fillMnnRec(++p);
      });
  }

  ngOnDestroy() {
    this.parsAnketScrollSubscription();

    (<any>window).inner.publicFunc2 = null;
    (<any>window).inner.publicFunc3 = null;
    (<any>window).inner.publicFuncChangeAnketState = null;
  }

  fieldTemplateOpen(dataItem: any) {
    this.fieldTemplateOpenedDataItem = dataItem;
    this.fieldTemplateOpenedEntityId = dataItem.id;
  }

  fieldTemplateCancel() {
    this.fieldTemplateOpenedDataItem = undefined;
  }

  fieldTemplateSelect(data: string) {
    if (this.fieldTemplateOpenedDataItem) {
      const controlType = this.fieldTemplateOpenedDataItem.controlType.code;
      if (controlType === 20) {
        this.fieldTemplateOpenedDataItem.anketItem.value = data;
      } else if (controlType === 50 || controlType === 60) {
        this.fieldTemplateOpenedDataItem.anketItem.valueAddOn.other = data;
      }
    }
    this.fieldTemplateCancel();
  }

  onDropDownListToggle(isDropDownListOpen: boolean) {
    this.isDropDownListOpen = isDropDownListOpen;
  }

  private SetExpandedRecursive(itemId: string) {
    if (this.expandedIds.indexOf(itemId) === -1) {
      this.expandedIds.push(itemId);
    }

    const parent = this.items.find(x => x.id === itemId);
    if (parent && parent.parentId) {
      this.SetExpandedRecursive(parent.parentId);
    }
  }

  publicFunc2(callback, saveCopyAsNew) {
    this.ngZone.run(() => this.privateFunc2(saveCopyAsNew, callback));
  }

  publicFunc3(callback) {
    this.ngZone.run(() => callback(this.privateFunc3()));
  }

  publicFuncChangeAnketState(callback, newState) {
    this.ngZone.run(() => this.privateFuncChangeAnketState(newState, callback));
  }

  publicFuncSignAnket(callback) {
    this.ngZone.run(() => this.privateFuncSignAnket(callback));
  }

  privateFunc2(saveCopyAsNew: boolean, callback) {
    if (saveCopyAsNew) {
      this.saveCopyAsNew(callback);
    } else {
      this.save(true, callback);
    }
  }

  privateFunc3() {
    const copyAnket = Object.assign({}, this.anket);
    copyAnket.anketItems.filter(x => x.schemeItem.controlTypeCode === 40).forEach(element => {
        if (!element.schemeItem.anyParams || !element.schemeItem.anyParams.addOnJson
          || !element.schemeItem.anyParams.addOnJson.customData && !element.schemeItem.anyParams.addOnJson.apiDataSource) {
          element.valueAny = element.value ? this.staffList.find(x => x.value === element.value) : null;
        }
    });
    return copyAnket;
  }

  privateFuncChangeAnketState(newState: number, callback) {
    if (!this.anket.id || this.anket.state === newState) {
      return;
    }

    this.anketsDataService.changeState(this.anketId, this.userId, newState).subscribe({ next: result => {
      if (result.success) {
        this.anket.state = result.result;
        callback(this.anket);
      }
    }});
  }

  privateFuncSignAnket(callback) {
    if (!this.anket.id) {
      return;
    }

    this.anketsDataService.changeState(this.anketId, this.userId, 2).subscribe({ next: result => {
      if (result.success) {
        this.anket.state = result.result;
        callback(this.anket);
      }
    }});
  }

  anketAddOnValueFill(anketItems: AnketItemModel[]) {
    if (!anketItems) {
      return;
    }

    for (let i = 0; i < anketItems.length; i++) {
      anketItems[i].schemeItem = this.getSchemeItem(anketItems[i]);
      anketItems[i].styles = this.getStyles(anketItems[i]);
      AnketItemModel.addOnFill(anketItems[i]);
    }
  }

  anketAddOnValueReFill(anketItems: AnketItemModel[]) {
    if (!anketItems) {
      return;
    }

    for (let i = 0; i < anketItems.length; i++) {
      AnketItemModel.addOnReFill(anketItems[i]);
    }
  }

  public getStyles(anketItem: AnketItemModel): string {
    const parStyles = anketItem.schemeItem.parameters.find(x => x.parameterType.code === 10);
    return parStyles
      ? JSON.parse(parStyles.value)
      : null;
  }

  onRightPaneScroll(ev: any) {
    if (this.checkRightPaneScrollLocker()) {
      return;
    }

    const positiveItems = this.anket.anketItems.filter(x => {
      const el = document.getElementById(`rightPaneAnketItem${x.schemeItemId}`);
      const elRect = el.getBoundingClientRect();
      return elRect.top - 82 > 0;
    });

    if (positiveItems.length < 1) {
      return;
    }

    const firstItem = positiveItems[0].schemeItem;

    if (firstItem) {
      this.SetExpandedRecursive(firstItem.id);
      this.selectedIds = [firstItem.id];
      this.scrollLeftToSchemeItemId(firstItem.id);
    } else {
      console.log('onRightPaneScroll firstItem is null, ev: ', ev);
    }
  }

  onTextFieldChange(dataItem: any) {
    if (dataItem.anyParams && dataItem.anyParams.addOnJson && dataItem.anyParams.addOnJson.type === 'formula') {
      const dest = this.anket.schemeVersion.scheme.schemeItems.find(x => x.alias === dataItem.anyParams.addOnJson.dest);
      if (dest) {
        let result: string;
        try {

          let f = dataItem.anyParams.addOnJson.value;

          // replace current value
          f = f.replace(/%value%/g, dataItem.anketItem.value);

          // replace other control's values
          const re = /@@.+?@@/g;
          const aliases = [];
          let tmp = [];
          let i = 0;
          while ((tmp = re.exec(f)) !== null) {
            const alias = tmp[0];
            if (aliases.indexOf(alias) === -1) {
              aliases.push(alias);
            }

            if (++i > 10) {
              break;
            }
          }

          aliases.forEach(alias => {
            const aliasTrimed = alias.substr(2, alias.length - 4);
            const aliasSchemeItem = this.anket.schemeVersion.scheme.schemeItems.find(x => x.alias === aliasTrimed);
            if (!aliasSchemeItem || !aliasSchemeItem.anketItem) {
              return;
            }

            const aliasValue = aliasSchemeItem.anketItem.value;
            f = f.replace(new RegExp(alias, 'g'), aliasValue);
          });

          // tslint:disable-next-line:no-eval
          result = eval(f).toString();
        } catch {
          result = '';
        }

        dest.anketItem.value = result;
      }
    }
  }

  onDopDateChange(dataItem: any) {
    if (dataItem.anyParams && dataItem.anyParams.addOnJson && dataItem.anyParams.addOnJson.dopDateTime
      && dataItem.anyParams.addOnJson.dopDateTime.validation) {
      const vld = dataItem.anyParams.addOnJson.dopDateTime.validation;
      const dest = this.anket.schemeVersion.scheme.schemeItems.find(x => x.alias === vld.dest);

      if (!dest) {
        return;
      }

      if (vld.type === 'more' || vld.type === 'less' || vld.type === 'notmore' || vld.type === 'notless') {
        const thisValue = dataItem.anketItem.valueAddOn.dopDateTimeDate;
        let destValue = dest.anketItem.valueAddOn.dopDateTimeDate;

        if (!thisValue || !destValue) {
          return;
        }

        destValue = new Date(destValue.toISOString());
        if (vld.type === 'more' && thisValue <= destValue) {
          destValue.setDate(destValue.getDate() + 1);
          dataItem.anketItem.valueAddOn.dopDateTimeDate = destValue;
        } else if (vld.type === 'less' && thisValue >= destValue) {
          destValue.setDate(destValue.getDate() - 1);
          dataItem.anketItem.valueAddOn.dopDateTimeDate = destValue;
        } else if (vld.type === 'notmore' && thisValue > destValue) {
          dataItem.anketItem.valueAddOn.dopDateTimeDate = destValue;
        } else if (vld.type === 'notless' && thisValue < destValue) {
          dataItem.anketItem.valueAddOn.dopDateTimeDate = destValue;
        }
      }
    }
  }

  leftPaneNodeClick(ev: any) {
    this.rightPaneScrollLocker = +new Date();
    this.scrollRightToSchemeItemId(ev.item.dataItem.id);
  }

  checkRightPaneScrollLocker(): boolean {
    return +new Date() - this.rightPaneScrollLocker < 800;
  }

  scrollLeftToSchemeItemId(schemeItemId: string) {
    const pane = document.getElementById('leftPane');
    const el = document.getElementById(`navItem_${schemeItemId}`);
    if (el) {
      const elRect = el.getBoundingClientRect();
      const paneRect = pane.getBoundingClientRect();

      pane.scrollTo({ top: pane.scrollTop - paneRect.height / 2 + elRect.top, behavior: 'smooth' });
    }
  }

  scrollRightToSchemeItemId(schemeItemId: string) {
    const pane = document.getElementById('rightPane');
    const el = document.getElementById(`rightPaneAnketItem${schemeItemId}`);
    const elRect = el.getBoundingClientRect();

    pane.scrollTo({ top: pane.scrollTop + elRect.top - 83, behavior: 'smooth' });
  }

  getSchemeItem(anketItem: AnketItemModel): SchemeItemEditModel {
    if (!this.scheme || !this.scheme.schemeItems) {
      return null;
    }

    this.scheme.schemeItems.forEach(schemeItem => {
      const multilineParam = schemeItem.parameters.find(x => x.parameterType.code === 40);
      const includeClarifyFieldParam = schemeItem.parameters.find(x => x.parameterType.code === 60);
      const datetimeTypeParam = schemeItem.parameters.find(x => x.parameterType.code === 70);
      const addOnJsonParam = schemeItem.parameters.find(x => x.parameterType.code === 80);
      const aoj = addOnJsonParam ? this.tryJsonParse(addOnJsonParam.value) : null;

      const p: any = {
        includeClarifyField: includeClarifyFieldParam && includeClarifyFieldParam.value === 'true',
        addOnJson: aoj,
        datetimeType: datetimeTypeParam ? datetimeTypeParam.value : null,
        multiline: multilineParam ? multilineParam.value === 'true' : true
      };

      if (aoj) {
        p.headerCssStyles = aoj.headerCssStyles ? JSON.parse(aoj.headerCssStyles) : null;
        p.buttonsContainerCssStyles = aoj.buttonsContainerCssStyles ? JSON.parse(aoj.buttonsContainerCssStyles) : null;
        p.textBoxCssStyles = aoj.textBoxCssStyles ? JSON.parse(aoj.textBoxCssStyles) : null;
        p.contentBlockCssStyles = aoj.contentBlockCssStyles ? JSON.parse(aoj.contentBlockCssStyles) : null;
        p.showTemlatesControl = aoj.showTemlatesControl;
        p.disabled = aoj.disabled;

        if (aoj.dopTextBox) {
          p.dopTextBox = aoj.dopTextBox;
          p.dopTextBox.cssStyles = p.dopTextBox.cssStyles ? JSON.parse(p.dopTextBox.cssStyles) : null;
        }

        if (aoj.dopDateTime) {
          p.dopDateTime = aoj.dopDateTime;
          p.dopDateTime.cssStyles = p.dopDateTime.cssStyles ? JSON.parse(p.dopDateTime.cssStyles) : null;
        }

        if (aoj.dopMnn) {
          p.dopMnn = aoj.dopMnn;
          p.dopMnn.cssStyles = p.dopMnn.cssStyles ? JSON.parse(p.dopMnn.cssStyles) : null;
        }
      }

      schemeItem.anyParams = p;
    });

    const result = this.scheme.schemeItems.filter(x => x.id === anketItem.schemeItemId);
    return result.length === 1 ? result[0] : null;
  }

  tryJsonParse(val: string): any {
    try {
      return val ? JSON.parse(val) : null;
    } catch (ex) {
      // console.log('tryJsonParse error', val, ex);
      return null;
    }
  }

  varItemClick(anketItem, ev, addOnJson) {
    if (this.disabled) {
      return;
    }

    AnketItemModel.toggleVariantsSelect(anketItem, ev.target.innerText);

    this.changeIfThenVisible(anketItem, addOnJson);
    this.firstButtonIsAllClick(anketItem, ev.target.innerText, addOnJson);
  }

  firstButtonIsAllClick(anketItem, targetText, addOnJson) {
    if (!addOnJson || !addOnJson.firstButtonIsAllClick || anketItem.variants.length < 1 || anketItem.variants[0] !== targetText) {
      return;
    }

    anketItem.valueAddOn.selected = [];
    anketItem.variants.forEach(x => { if (x === targetText) { return; } anketItem.valueAddOn.selected.push(x); });
  }

  private _changeIfThenVisible = {};
  changeIfThenVisibleInit(anketItem, itv) {
    if (!anketItem || !itv) {
      return;
    }

    const displayNone = anketItem.valueAddOn.selected.indexOf(itv.source) < 0;
    itv.dests.forEach(el => {
      const selector = `.rpai-${el}`;
      this._changeIfThenVisible[selector] = { selector, displayNone };
    });
  }

  changeIfThenVisible(anketItem, addOnJson) {
    if (!addOnJson || !addOnJson.ifThenVisible) {
      return;
    }

    const itv = addOnJson.ifThenVisible;
    const displayNone = anketItem.valueAddOn.selected.indexOf(itv.source) < 0;
    itv.dests.forEach(el => {  
      const selector = `.rpai-${el}`;
      const docEl = document.querySelector(selector);
      if (docEl) {
        if (this._changeIfThenVisible[selector]) {
          delete this._changeIfThenVisible[selector];
        }
        (<any>docEl).style.display = displayNone ? 'none' : '';
      }
    });
  }

  checksItemClick(dataItem, varItem) {
    if (this.disabled) {
      return;
    }
    AnketItemModel.toggleChecksVariantsSelect(dataItem.anketItem, varItem);
  }

  isExpanded = (dataItem: any): boolean => true;

  onCollapse(args: ExpandEvent): void {
      this.expandedIds = this.expandedIds.filter(id => id !== args.dataItem.id);
  }

  onExpand(args: ExpandEvent): void {
      this.expandedIds.push(args.dataItem.id);
  }

  getAutoSaveAnketHash(that: any): string {
    const copyAnketItems: AnketItemModel[] = Object.assign([], that.anket.anketItems);
    if (copyAnketItems.find(x => !x.schemeItem)) {
      return undefined;
    }

    for (let i = 0; i < copyAnketItems.length; i++) {
      AnketItemModel.addOnReFill(copyAnketItems[i]);
    }

    return JSON.stringify(copyAnketItems.map(x => that.getAnketitemValuesData(x)));
  }

  getAnketitemValuesData(anketItem: AnketItemModel): any {
    return {
      siId: anketItem.schemeItemId,
      v: anketItem.value
    };
  }

  autoSave(that: any) {
    if (that.autoSaveIntervalLock) {
      return;
    }

    const hash = that.getAutoSaveAnketHash(that);
    if (!hash || hash === that.autoSaveAnketHash) {
      return;
    }

    that.autoSaveAnketHash = hash;
    that.anketsDataService.autoSave(that.userId, that.patientId, that.schemeId, that.anketId, hash);
  }

  autoSaveDialogOpen(autoSaveData: any) {
    this.autoSaveDialogMessage = autoSaveData.message;
    this.autoSaveDialogData = autoSaveData.data;
    this.autoSaveDialogActive = true;
  }

  autoSaveDialogClose() {
    this.autoSaveDialogActive = false;
  }

  autoSaveDialogOk() {
    const autoSaveData: any[] = JSON.parse(this.autoSaveDialogData);
    autoSaveData.forEach(el => {
      const anketItem = this.anket.anketItems.find(x => x.schemeItemId === el.siId);
      anketItem.value = el.v;
      AnketItemModel.addOnFill(anketItem);
    });
    this.autoSaveDialogActive = false;
  }

  save(needContinue: boolean, callback) {
    this.autoSaveIntervalLock = true;
    if (!this.anket.creatorUserId) {
      this.anket.creatorUserId = this.userId;
    }
    this.anket.updaterUserId = this.userId;

    this.anketAddOnValueReFill(this.anket.anketItems);

    this.anketsDataService.save(this.anket).subscribe((anket) => {
      if (needContinue) {
        this.anket = anket;
        this.anketId = this.anket.id;
        this.isNew = !this.anketId;
        this.anketAddOnValueFill(anket.anketItems);

        anket.anketItems.forEach(element => {
          element.schemeItem.anketItem = element;
        });

        this.autoSaveAnketHash = this.getAutoSaveAnketHash(this);
        this.autoSaveIntervalLock = false;

        callback(anket);
      } else {
        this.router.navigate(['/ankets-list/', this.anket.schemeVersion.schemeId]);
      }
    });
  }

  saveCopyAsNew(callback) {
    if (!this.anket || !this.anket.id) {
      return null;
    }

    this.anketAddOnValueReFill(this.anket.anketItems);
    this.anketsDataService.save(this.anket, true).subscribe((anket) => {
      this.anketAddOnValueFill(this.anket.anketItems);
      this.anketAddOnValueFill(anket.anketItems);

      callback(anket);
    });
  }

  hasInVariantsSelect(anketItem: AnketItemModel, value: string): boolean {
    return anketItem.valueAddOn.selected.indexOf(value) > -1;
  }

  hasInChecksSelect(dataItem: SchemeItemEditModel, value: any): boolean {
    return dataItem.anketItem.valueAddOn
      && dataItem.anketItem.valueAddOn.checksModel
      && !!(dataItem.anketItem.valueAddOn.checksModel.find(x => x.id === value.id && x.selected));
  }

  valueAddOnResult(valueAddOn: ValueAddOnModel): string {
    return [ valueAddOn.selected.join(', '), valueAddOn.other, valueAddOn.dopTextBox ].filter(x => x != null && x !== '').join(', ');
  }

  isTemplateNeedShow(dataItem: any): boolean {
    if (dataItem.anyParams && dataItem.anyParams.showTemlatesControl) {
      if (dataItem.controlType.code === 20) {
        return true;
      }

      if (dataItem.controlType.code === 50 || dataItem.controlType.code === 60) {
        return dataItem.anyParams && dataItem.anyParams.includeClarifyField;
      }
    }

    return false;
  }

  isDopTextBoxNeedShow(dataItem: any): boolean {
    if (dataItem.anyParams && dataItem.anyParams.dopTextBox) {
      const dopTb = dataItem.anyParams.dopTextBox;
      if (dopTb.condition && dopTb.condition.type === 'visible') {
        return dataItem.anketItem && dataItem.anketItem.valueAddOn
          && dataItem.anketItem.valueAddOn.selected
          && dataItem.anketItem.valueAddOn.selected.indexOf(dopTb.condition.selected) > -1;
      }

      return true;
    }

    return false;
  }

  isDopDateTimeNeedShow(dataItem: any): boolean {
    if (dataItem.anyParams && dataItem.anyParams.dopDateTime) {
      const dopDt = dataItem.anyParams.dopDateTime;
      if (dopDt.condition && dopDt.condition.type === 'visible') {
        return dataItem.anketItem && dataItem.anketItem.valueAddOn
          && dataItem.anketItem.valueAddOn.selected
          && dataItem.anketItem.valueAddOn.selected.indexOf(dopDt.condition.selected) > -1;
      }

      return true;
    }

    return false;
  }

  isDopMnnNeedShow(dataItem: any): boolean {
    if (dataItem.anyParams && dataItem.anyParams.dopMnn) {
      const dop = dataItem.anyParams.dopMnn;
      if (dop.condition && dop.condition.type === 'visible') {
        return dataItem.anketItem && dataItem.anketItem.valueAddOn
          && dataItem.anketItem.valueAddOn.selected
          && dataItem.anketItem.valueAddOn.selected.indexOf(dop.condition.selected) > -1;
      }

      return true;
    }

    return false;
  }

  isDopMkb10NeedShow(dataItem: any): boolean {
    if (dataItem.anyParams && dataItem.anyParams.addOnJson && dataItem.anyParams.addOnJson.dopMkb10) {
      const dop = dataItem.anyParams.addOnJson.dopMkb10;
      if (dop.condition && dop.condition.type === 'visible') {
        return dataItem.anketItem && dataItem.anketItem.valueAddOn
          && dataItem.anketItem.valueAddOn.selected
          && dataItem.anketItem.valueAddOn.selected.indexOf(dop.condition.selected) > -1;
      }

      return true;
    }

    return false;
  }

  isChecksAllItemsDopTextBoxNeedShow(dataItem: any, varItem: any): boolean {
    if (dataItem.anyParams.addOnJson.checks.allItemsDopTextBox
      && dataItem.anketItem.valueAddOn.checksModel) {
      const varItemModel = dataItem.anketItem.valueAddOn.checksModel.find(x => x.id === varItem.id);
      return varItemModel && varItemModel.selected;
    }

    return false;
  }

  isChecksAllItemsDopMnnNeedShow(dataItem: any, varItem: any): boolean {
    if (dataItem.anyParams.addOnJson.checks.allItemsDopMnn
      && dataItem.anketItem.valueAddOn.checksModel) {
      const varItemModel = dataItem.anketItem.valueAddOn.checksModel.find(x => x.id === varItem.id);
      return varItemModel && varItemModel.selected;
    }

    return false;
  }

  isChecksAllItemsDopMkb10NeedShow(dataItem: any, varItem: any): boolean {
    if (dataItem.anyParams.addOnJson.checks.allItemsDopMkb10
      && dataItem.anketItem.valueAddOn.checksModel) {
      const varItemModel = dataItem.anketItem.valueAddOn.checksModel.find(x => x.id === varItem.id);
      return varItemModel && varItemModel.selected;
    }

    return false;
  }

  getCheckDopSimpleInput(dataItem: SchemeItemEditModel, varItem: any, dItem: any) {
     const checkItem = dataItem.anketItem.valueAddOn.checksModel.find(x => x.id === varItem.id);
     if (checkItem && checkItem.dopSimples) {
      const checkItemDopItem = checkItem.dopSimples.find(x => x.id === dItem.id);
      if (checkItemDopItem) {
        return checkItemDopItem.value;
      }
     }

     return '';
  }

  getCheckDopSimpleDate(dataItem: SchemeItemEditModel, varItem: any, dItem: any) {
     const checkItem = dataItem.anketItem.valueAddOn.checksModel.find(x => x.id === varItem.id);
     if (checkItem && checkItem.dopSimples) {
      const checkItemDopItem = checkItem.dopSimples.find(x => x.id === dItem.id);
      if (checkItemDopItem && checkItemDopItem.value) {
        return new Date(checkItemDopItem.value);
      }
     }

     return '';
  }

  valueOfCheckDopSimpleInputChange(ev, dataItem, varItem, dItem) {
    const checkItem = dataItem.anketItem.valueAddOn.checksModel.find(x => x.id === varItem.id);
     if (checkItem && checkItem.dopSimples) {
      const checkItemDopItem = checkItem.dopSimples.find(x => x.id === dItem.id);
      if (checkItemDopItem) {
        checkItemDopItem.value = ev.target.value;
      }
     }
  }

  valueOfCheckDopSimpleDateChange(ev, dataItem, varItem, dItem) {
    const checkItem = dataItem.anketItem.valueAddOn.checksModel.find(x => x.id === varItem.id);
     if (checkItem && checkItem.dopSimples) {
      const checkItemDopItem = checkItem.dopSimples.find(x => x.id === dItem.id);
      if (checkItemDopItem) {
        checkItemDopItem.value = ev.toISOString();

        if (dItem.innerCalc && dItem.innerCalc.formula && dItem.innerCalc.dest) {
          const dest = checkItem.dopSimples
            ? checkItem.dopSimples.find(x => x.id === dItem.innerCalc.dest)
            : null;

          if (dest) {
            let result: string;
            try {
              let f = dItem.innerCalc.formula;

              // replace other control's values
              const re = /##.+?##/g;
              const ids = [];
              let tmp = [];
              let i = 0;
              while ((tmp = re.exec(f)) !== null) {
                const did = tmp[0];
                if (ids.indexOf(did) === -1) {
                  ids.push(did);
                }

                if (++i > 10) {
                  break;
                }
              }

              ids.forEach(id => {
                const idTrimed = id.substr(2, id.length - 4);
                const idItemScheme = varItem.dopAnySimples.items.find(x => x.id === idTrimed);
                const idItem = checkItem.dopSimples.find(x => x.id === idTrimed);
                if (!idItemScheme || !idItem) {
                  return;
                }

                f = f.replace(new RegExp(id, 'g'), idItemScheme.type === 'date' && idItem.value ? +new Date(idItem.value) : idItem.value);
              });

              // tslint:disable-next-line:no-eval
              result = eval(f).toString();
            } catch (ex) {
              result = '';
            }

            dest.value = result;
          }
        }
      }
     }
  }

  test(obj) {

    // отладочка
    ((sort, alias) => {
      // const anketItem = this.anket.anketItems.find(x => x.schemeItem.sort === sort);
      console.log(`dbg${alias}`, sort);
    })(19300, 'tst');
    
  }
}
