import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { map } from 'rxjs/operators';
import { FieldTemplateConsts } from 'src/app/consts/field-template-consts';
import { ForUiCombobox } from 'src/app/models/ForUiCombobox';
import { FieldTemplateService } from 'src/app/services/field-template.service';

const createFormGroup = (dataItem) =>
    new FormGroup({
      id: new FormControl(dataItem.id),
      entityId: new FormControl(dataItem.entityId),
      userId: new FormControl(dataItem.userId),
      date: new FormControl(dataItem.date),
      data: new FormControl(dataItem.data ? parseInt(dataItem.data, 10) : null, Validators.required)
    });

@Component({
  selector: 'app-mkb10-templ-settings',
  templateUrl: './mkb10-templ-settings.component.html',
  styleUrls: ['./mkb10-templ-settings.component.scss']
})
export class Mkb10TemplSettingsComponent implements OnInit {
  @ViewChild(GridComponent, {static: false}) private grid: GridComponent;

  @Input() public userId: number;
  @Input() public opened = false;
  @Input() public allMkb10Emiases: ForUiCombobox[] = [];
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() select: EventEmitter<any> = new EventEmitter();

  public data: any[];
  public loading = false;
  public editDataItem: any;
  public isPerson = true;

  public formGroup: FormGroup;

  private editedRowIndex: number;

  public fullModel: any[];

  public virtual: any = {
    itemHeight: 30
  };

  constructor(
    private fieldTemplateService: FieldTemplateService,
    ) { }

  ngOnInit() {
    this.refreshData();
  }

  public addHandler({ sender }): void {
    this.closeEditor();

    this.formGroup = createFormGroup({
      entityId: FieldTemplateConsts.diagnozEntityId,
      userId: this.isPerson ? this.userId : null,
      date: new Date(),
      data: null
    });

    sender.addRow(this.formGroup);
  }

  public editHandler({sender, dataItem, rowIndex, columnIndex}) {
    this.closeEditor();
    this.editedRowIndex = rowIndex;
    this.formGroup = createFormGroup(dataItem);
    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  public removeHandler({ dataItem }) {
    this.loading = true;
    this.fieldTemplateService.remove(dataItem.id).subscribe(x => this.refreshData());
  }

  public cancelHandler() {
    this.closeEditor();
  }

  public saveHandler() {
    if (this.formGroup.invalid) {
      return;
    }

    this.loading = true;
    this.fieldTemplateService.save({
      id: this.formGroup.value.id,
      entityId: this.formGroup.value.entityId,
      userId: this.formGroup.value.userId,
      date: this.formGroup.value.date,
      data: this.formGroup.value.data.toString(),
    }).subscribe(x => this.refreshData());

    this.grid.closeRow(this.editedRowIndex);
  }

  public isPersonSelectedChange(isPerson: boolean) {
    const needRefresh = this.isPerson !== isPerson;
    this.isPerson = isPerson;
    if (needRefresh) {
      this.refreshData();
    }
  }

  public onSelect(entity: any) {
    if (!entity || !entity.data) {
      return;
    }

    this.select.emit(parseInt(entity.data, 10));
  }

  private closeEditor() {
    this.grid.closeRow(this.editedRowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public onClose() {
    this.cancel.emit();
  }

  public getMkb10Name(mkb10Id: any): string {
    const mkb10 = this.allMkb10Emiases.find(x => x.id === parseInt(mkb10Id, 10));

    if (!mkb10)  {
      return '-';
    }

    return `${mkb10.code}. ${mkb10.name}`;
  }

  private refreshData() {
    this.fieldTemplateService.getPage(FieldTemplateConsts.diagnozEntityId).pipe(map(y => {
      this.fullModel = y;
      this.data = this.isPerson ? this.fullModel.filter(x => x.userId === this.userId) : this.fullModel.filter(x => !x.userId);
      this.loading = false;
    })).subscribe();
  }

}
