import { Component, Input, OnInit } from '@angular/core';
import { AnketsDataService } from '../data.service';

@Component({
  selector: 'app-combobox-custom-source',
  templateUrl: './combobox-custom-source.component.html',
  styleUrls: ['./combobox-custom-source.component.scss']
})
export class ComboboxCustomSourceComponent implements OnInit {

  @Input() public disabled: boolean;
  @Input() public loadByPages: boolean;
  @Input() public dataItem: any;
  @Input() public apiDataSource: any;

  public data: any[] = [];

  constructor(
    private dataService: AnketsDataService,
  ) { }

  ngOnInit() {
    this.fillRecs(this.loadByPages ? 1 : 0);
  }

  fillRecs(p) {
    this.dataService.getCustomComboboxData(this.apiDataSource, p).subscribe(
      (data) => {
        if (this.loadByPages) {
          this.data = this.data.concat(data.map(x => {
            return { text: x.text, value: x.id.toString(), name: x.name };
          }));
        } else {
          this.data = data.map(x => {
            return { text: x.text, value: x.id.toString(), name: x.name };
          });
        }
      });
  }
}
