import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { AppConfig } from 'src/shared/app.config';
import { State } from '@progress/kendo-data-query';

const CREATE_ACTION = 'add';
const UPDATE_ACTION = 'save';
const REMOVE_ACTION = 'delete';

@Injectable()
export class SchemasDataService extends BehaviorSubject<any[]> {
  constructor(private http: HttpClient) {
      super([]);
  }


  private addOnHeaders: HttpHeaders = new HttpHeaders({
    // 'Authorization': 'Bearer BQDWZC2Y_iSV3rfmAhv-QxDpmhhJbbmgCnspy7HpIZPX5CbJ74D4Xl4aOyXLUL4smF2gZ_V3wiSXLxdLFPY',
    'Content-Type': 'application/json'
  });

  private data: any[] = [];
  public state: State = {
    skip: 0,
    take: 20,
    filter: { filters: [], logic: 'or' },
    group: [],
    sort: []
};

  public read() {
      if (this.data.length) {
          return super.next(this.data);
      }

      this.fetch()
          .subscribe(data => {
              super.next(data);
          });
  }

  public save(data: any, isNew?: boolean) {

      const action = isNew ? CREATE_ACTION : UPDATE_ACTION;

      this.reset();

      this.fetch(action, data)
          .subscribe(() => this.read(), () => this.read());
  }

  public remove(data: any) {
      this.reset();

      this.fetch(REMOVE_ACTION, data)
          .subscribe(() => this.read(), () => this.read());
  }

  public resetItem(dataItem: any) {
      if (!dataItem) {
        return;
      }

      const originalDataItem = this.data.find(item => item.id === dataItem.id);

      Object.assign(originalDataItem, dataItem);

      super.next(this.data);
  }

  private reset() {
      this.data = [];
  }

  private fetch(action: string = '', data?: any): Observable<any> {
    switch (action) {
      case '': {
          const url = `${AppConfig.resourceApiUrl}/api/scheme/page`;
          return this.http.post(url, this.state, { headers: this.addOnHeaders }).pipe(
              map(res => <any[]>res)
            );
      }
      case 'add': {
        const url = `${AppConfig.resourceApiUrl}/api/scheme/add`;
        return this.http.post(url, data, { headers: this.addOnHeaders }).pipe(
            map(res => <any>res)
          );
      }
      case 'save': {
        const url = `${AppConfig.resourceApiUrl}/api/scheme/save`;
        return this.http.post(url, data, { headers: this.addOnHeaders }).pipe(
            map(res => <any>res)
          );
      }
      case 'delete': {
        const url = `${AppConfig.resourceApiUrl}/api/scheme/delete?id=${data}`;
        return this.http.post(url, null, { headers: this.addOnHeaders }).pipe(
            map(res => <any>res)
          );
      }
    }
  }
}
