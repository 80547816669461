import { SchemeVersionModel } from '../schemas/scheme-version/scheme-version-model';
import { AnketItemModel } from './anket-item-model';

export class AnketModel {
  public id: string;
  public number: number;
  public schemeVersionId: string;
  public patientId: number;
  public date: Date;
  public doctorId: number;
  public state: number;

  public creatorUserId: number;
  public updaterUserId: number;

  public schemeVersion: SchemeVersionModel;
  public anketItems: AnketItemModel[];
  public patient: any;
  public doctor: any;
}
