import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AppConfig } from 'src/shared/app.config';
import { ControlTypeModel } from 'src/app/control-types/control-type-model';
import { map } from 'rxjs/operators';

@Injectable()
export class SchemeItemEditDataService {
  constructor(private http: HttpClient) { }

  private addOnHeaders: HttpHeaders = new HttpHeaders({
    // 'Authorization': 'Bearer BQDWZC2Y_iSV3rfmAhv-QxDpmhhJbbmgCnspy7HpIZPX5CbJ74D4Xl4aOyXLUL4smF2gZ_V3wiSXLxdLFPY',
    'Content-Type': 'application/json'
  });

  getAllControlTypes(): Observable<ControlTypeModel[]> {
    const url = `${AppConfig.resourceApiUrl}/api/control-type/page`;
    return this.http.post<ControlTypeModel[]>(url, {}, { headers: this.addOnHeaders }).pipe(
      map(({ data }: any): ControlTypeModel[] => {
          return data;
      }));
  }
}
