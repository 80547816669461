import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppConfig } from 'src/shared/app.config';

@Injectable()
export class KladrDistrictsService extends BehaviorSubject<any> {

  private addOnHeaders: HttpHeaders = new HttpHeaders({
    // 'Authorization': 'Bearer BQDWZC2Y_iSV3rfmAhv-QxDpmhhJbbmgCnspy7HpIZPX5CbJ74D4Xl4aOyXLUL4smF2gZ_V3wiSXLxdLFPY',
    'Content-Type': 'application/json'
  });

  public loading = false;

  constructor(private http: HttpClient) {
    super(null);
  }

  public query(filter: string, regionId: number): void {
    this.fetch(filter, regionId).subscribe((x) => super.next(x));
  }

  private fetch(filter: string, regionId: number): any {
    this.loading = true;

    const url = `${AppConfig.resourceApiUrl}/api/wsdoctor/get-kladr-districts`;
    return this.http.post(url, { text: filter, parentId: regionId }, { headers: this.addOnHeaders }).pipe(
      tap(() => (this.loading = false))
    );
  }
}
