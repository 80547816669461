import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { AppConfig } from 'src/shared/app.config';
import { SchemeItemEditModel } from './scheme-item-edit/scheme-item-edit-model';
import { SchemeItemFilterConsts } from 'src/app/consts/scheme-item-filter-consts';
import { SchemeItemModel } from './scheme-item-model';

@Injectable()
export class SchemeItemsDataService extends BehaviorSubject<any[]> {
  constructor(private http: HttpClient) {
      super([]);
  }

  private addOnHeaders: HttpHeaders = new HttpHeaders({
    // 'Authorization': 'Bearer BQDWZC2Y_iSV3rfmAhv-QxDpmhhJbbmgCnspy7HpIZPX5CbJ74D4Xl4aOyXLUL4smF2gZ_V3wiSXLxdLFPY',
    'Content-Type': 'application/json'
  });

  private data: any[] = [];
  private currentSchemeId: string = null;

  public setCurrentSchemeId(schemeId: string) {
    this.currentSchemeId = schemeId;
  }

  public getSchemeItemList(schemeId: string): Observable<SchemeItemModel[]> {
    const url = `${AppConfig.resourceApiUrl}/api/scheme-items/page`;
    return this.http.post(url, this.getBody(schemeId), { headers: this.addOnHeaders }).pipe(
      map(res => (<any>res).data)
    );
  }

  public read(callback: any = null) {
    if (this.data.length) {
        return super.next(this.data);
    }

    const url = `${AppConfig.resourceApiUrl}/api/scheme-items/page`;
    return this.http.post(url, this.getBody(this.currentSchemeId), { headers: this.addOnHeaders }).pipe(
      map(res => (<any>res).data)
    ).subscribe(data => {
        super.next(data);
        if (callback) {
          callback(data);
        }
    });
  }

  public save(data: SchemeItemEditModel, isNew: boolean) {
    const url = `${AppConfig.resourceApiUrl}/api/scheme-items/${isNew ? 'add' : 'save'}`;
    return this.http.post(url, data, { headers: this.addOnHeaders }).pipe(
      map(res => (<any>res).result)
    ).subscribe(() => this.read(), () => this.read());
  }

  public remove(schemeItemId: string) {
    const url = `${AppConfig.resourceApiUrl}/api/scheme-items/delete?id=${schemeItemId}`;

    return this.http.post(url, null, { headers: this.addOnHeaders }).pipe(
      map(res => <any>res)
    ).subscribe(() => this.read(), () => this.read());
  }

  public getSchemeName(schemeId: string): Observable<string> {
    const url = `${AppConfig.resourceApiUrl}/api/scheme/get-name?id=${schemeId}`;

    return this.http.get(url, { headers: this.addOnHeaders }).pipe(
      map(res => (<any>res).result)
    );
  }

  private getBody(schemeId: string): any {
    return {
      filters: [ { id: SchemeItemFilterConsts.filterBySchemeId, parameters: { value: schemeId } } ]
    };
  }
}
