import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { AnketsDataService } from 'src/app/ankets/data.service';
import { SchemeEditModel } from '../scheme-edit-model';

@Component({
  selector: 'app-scheme-edit',
  templateUrl: './scheme-edit.component.html',
  styleUrls: ['./scheme-edit.component.scss']
})
export class SchemeEditComponent {
  constructor(
    private anketsDataService: AnketsDataService,
  ) {

  }

  public active = false;
  public editForm: FormGroup = new FormGroup({
      id: new FormControl(),
      schemeName: new FormControl('', Validators.required),
      alias: new FormControl(),
      added: new FormControl(),
  });

  public sImport = '';
  public sImportRes = '';

  @Input() public isNew = false;

  @Input() public set model(entity: SchemeEditModel) {
      this.editForm.reset(entity);
      this.active = entity !== undefined;
  }

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<SchemeEditModel> = new EventEmitter();

  public onSave(e): void {
      e.preventDefault();
      this.save.emit(this.editForm.value);
      this.active = false;
  }

  public onCancel(e): void {
      e.preventDefault();
      this.closeForm();
  }

  public import() {
    this.anketsDataService.importScheme(this.sImport).subscribe(x => this.sImportRes = x);
  }

  public exportScheme() {
    this.anketsDataService.exportScheme(this.sImport).subscribe(x => this.sImportRes = x);
  }

  public exportAnket() {
    this.anketsDataService.exportAnket(this.sImport).subscribe(x => this.sImportRes = x);
  }

  private closeForm(): void {
      this.active = false;
      this.cancel.emit();
  }
}
