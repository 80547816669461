import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'src/shared/app.config';

@Injectable()
export class AppConfigProvider {

    constructor(private http: HttpClient) {

    }

    load() {
        return new Promise((resolve, reject) => {
            this.http
                .get('/assets/appconfig.json')
                .subscribe((response: any) => {
                    AppConfig.init(response.resourceApiUrl, response.identityServerUrl, response.wsDoctorUrl);
                    resolve(true);
                });
        });
    }
}
