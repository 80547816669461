import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';

import { ParameterTypesDataService } from './data.service';


@Component({
  selector: 'app-parameter-types',
  templateUrl: './parameter-types.component.html',
})
export class ParameterTypesComponent implements OnInit {
  public view: Observable<any>;
  public formGroup: FormGroup;
  public state: State = {
      skip: 0,
      take: 20,
      filter: { filters: [], logic: 'or' },
      group: [],
      sort: []
  };

  constructor(private dataService: ParameterTypesDataService) {

  }

  public ngOnInit(): void {
      this.view = this.dataService;
      this.dataService.read();
  }
}
