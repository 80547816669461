import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';

import { SchemasDataService } from '../schemas/data.service';



@Component({
  selector: 'app-ankets-component',
  templateUrl: './ankets.component.html'
})
export class AnketsComponent implements OnInit {
  public view: Observable<any>;
  public gridState: any = {
    sort: [],
    skip: 0,
    take: 100
  };

  constructor(private schemasDataService: SchemasDataService) {

  }

  public ngOnInit(): void {
    this.view = this.schemasDataService;
    this.schemasDataService.read();
  }

  public dataStateChange(state: DataStateChangeEvent): void {
      this.gridState = state;
      this.schemasDataService.read();
  }
}
