import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ctrl-stomtable',
  templateUrl: './ctrl-stomtable.component.html',
  styleUrls: ['./ctrl-stomtable.component.scss']
})
export class CtrlStomtableComponent implements OnInit {

  @Input() public disabled: boolean; 
  @Input() public dataItem: any;

  public get v(): string[] {
    return this.dataItem.anketItem.valueAny;
  }

  constructor() { }

  ngOnInit() {
    if (!this.v || this.v.length != 64) {
      this.dataItem.anketItem.valueAny = [];
      for (var i = 0; i < 64; i++)
        this.dataItem.anketItem.valueAny[i] = '';
    }
  }

}
