import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppConfig } from 'src/shared/app.config';
import { CertVaccinFilterConsts } from '../consts/cert-vaccin-filter-consts';

const CREATE_ACTION = 'add';
const UPDATE_ACTION = 'save';
const REMOVE_ACTION = 'delete';

@Injectable()
export class CertVaccinService extends BehaviorSubject<any[]> {
  private addOnHeaders: HttpHeaders = new HttpHeaders({
    // 'Authorization': 'Bearer BQDWZC2Y_iSV3rfmAhv-QxDpmhhJbbmgCnspy7HpIZPX5CbJ74D4Xl4aOyXLUL4smF2gZ_V3wiSXLxdLFPY',
    'Content-Type': 'application/json'
  });

  public loading = false;

  constructor(private http: HttpClient) {
    super([]);
  }

  public data: any[] = [];
  private patientId: number;

  public read(patientId: number) {
    this.patientId = patientId;

    this.loading = true;
    this.fetch('page', this.getBody())
      .pipe(
        tap((data) => {
          this.data = data;
        })
      )
      .subscribe((data) => {
        super.next(data);
      });
  }

  public save(data: any, isNew?: boolean) {
    const action = isNew ? CREATE_ACTION : UPDATE_ACTION;

    this.reset();

    if (data.date) {
      data.date = moment(data.date).format('DD.MM.YYYY');
    }
    if (data.srok) {
      data.srok = moment(data.srok).format('DD.MM.YYYY');
    }

    this.loading = true;
    this.fetch(action, data).subscribe(
      () => this.read(this.patientId),
      () => this.read(this.patientId)
    );
  }

  public remove(data: any) {
    this.reset();

    this.loading = true;
    this.fetch(`delete?id=${data.id}`, {}).subscribe(
      () => this.read(this.patientId),
      () => this.read(this.patientId)
    );
  }

  public resetItem(dataItem: any) {
    if (!dataItem) {
      return;
    }

    const originalDataItem = this.data.find(
      (item) => item.id === dataItem.id
    );

    Object.assign(originalDataItem, dataItem);

    super.next(this.data);
  }

  private reset() {
    this.data = [];
  }

  private fetch(action, data): Observable<any[]> {
    const url = `${AppConfig.resourceApiUrl}/api/cert-vaccin/${action}`;
    return this.http.post(url, data, { headers: this.addOnHeaders }).pipe(
      tap(() => (this.loading = false)),
      map((res: any) => {
        if (res.data.forEach) {
          res.data.forEach(element => {
            element.date = moment(element.date, 'DD.MM.YYYY').toDate();
          });
        }
        return <any[]>res.data;
      })
    );
  }

  private getBody(): any {
    return {
      filters: [ { id: CertVaccinFilterConsts.filterByPatientId, parameters: { value: this.patientId } } ]
    };
  }
}
