import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, BehaviorSubject } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { GridDataResult } from '@progress/kendo-angular-grid';
import {
    State,
    toDataSourceRequestString,
} from '@progress/kendo-data-query';
import { AppConfig } from 'src/shared/app.config';
import { ParameterTypeModel } from './parameter-type-model';

@Injectable()
export class ParameterTypesDataService extends BehaviorSubject<any[]> {

    constructor(private http: HttpClient) {
        super([]);
    }

    private addOnHeaders: HttpHeaders = new HttpHeaders({
      // 'Authorization': 'Bearer BQDWZC2Y_iSV3rfmAhv-QxDpmhhJbbmgCnspy7HpIZPX5CbJ74D4Xl4aOyXLUL4smF2gZ_V3wiSXLxdLFPY',
      'Content-Type': 'application/json'
    });

    private data: any[] = [];
    public state: State = {
        skip: 0,
        take: 20,
        filter: { filters: [], logic: 'or' },
        group: [],
        sort: []
    };

    public read() {
        if (this.data.length) {
            return super.next(this.data);
        }

        this.fetch()
            .pipe(
                tap(data => {
                    this.data = data;
                })
            )
            .subscribe(data => {
                super.next(data);
            });
    }


    public fetch(dataItem?: any, action: string = ''): Observable<any> {

        switch (action) {
            case '': {
                const queryStr = `${toDataSourceRequestString(this.state)}`;

                const url = `${AppConfig.resourceApiUrl}/api/scheme-item-parameter-type/page`;
                return this.http.post(url, this.state, { headers: this.addOnHeaders }).pipe(
                    // Process the response
                    map(({ data, total }: GridDataResult): GridDataResult => {
                        return {
                            data: data,
                            total: total
                        };
                    }
                    ));
            }
        }
    }

    getAllParameterTypes(): Observable<ParameterTypeModel[]> {
      const url = `${AppConfig.resourceApiUrl}/api/scheme-item-parameter-type/page`;
      return this.http.post<ParameterTypeModel[]>(url, {}, { headers: this.addOnHeaders }).pipe(
        map(({ data }: any): ParameterTypeModel[] => {
            return data;
        }));
    }
}
