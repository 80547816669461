import { Component, Input, OnInit } from '@angular/core';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { AnketItemModel } from '../anket-item-model';

@Component({
  selector: 'app-ctrl-pokaz-osmotr',
  templateUrl: './ctrl-pokaz-osmotr.component.html',
  styleUrls: ['./ctrl-pokaz-osmotr.component.scss']
})
export class CtrlPokazOsmotrComponent implements OnInit {

  @Input() public disabled: boolean;

  public collapsed = false;

  constructor() { }

  ngOnInit() {
    if (this.collapsed) {
      window.document.body.classList.add('obshiy-osmotr-collaps');
    }
  }

  ext() {
    this.collapsed = !this.collapsed;
    if (this.collapsed) {
      window.document.body.classList.add('obshiy-osmotr-collaps');
    } else {
      window.document.body.classList.remove('obshiy-osmotr-collaps');
    }
  }

}
