import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SchedulerModelFields } from '@progress/kendo-angular-scheduler';
import { map } from 'rxjs/operators';
import { AppConfig } from 'src/shared/app.config';

const schedulerfields: SchedulerModelFields = {
  id: 'TaskID',
  start: 'Start',
  end: 'End',
};

@Injectable()
export class BookingsService {

  private addOnHeaders: HttpHeaders = new HttpHeaders({
    // 'Authorization': 'Bearer BQDWZC2Y_iSV3rfmAhv-QxDpmhhJbbmgCnspy7HpIZPX5CbJ74D4Xl4aOyXLUL4smF2gZ_V3wiSXLxdLFPY',
    'Content-Type': 'application/json'
  });

  constructor(private http: HttpClient) { }

  getBookingsForDoctor(medStaffId: number) {
    const url = `${AppConfig.resourceApiUrl}/api/wsdoctor/get-bookings-for-doctor`;
    return this.http.post(url, { medStaffId }, { headers: this.addOnHeaders }).pipe(
      map(res => {
        const result = <any[]>res;
        result.forEach(element => {
          element.start = new Date(element.start);
          element.end = new Date(element.end);
        });
        return result;
      })
    );
  }

  saveDoctrosBookings(ent: any) {
    const url = `${AppConfig.resourceApiUrl}/api/wsdoctor/get-save-doctors-bookings`;
    return this.http.post(url, { medStaffId: ent.medStaffId,
      procedureId: ent.procedureId,
      comment: ent.comment,
      editStart: ent.editStart,
      editEnd: ent.editEnd,
      userId: ent.userId,
      patientId: ent.patientId,
    }, { headers: this.addOnHeaders }).pipe(
      map(res => {
        const result = <any>res;
        result.time = new Date(result.time);
        return result;
      })
    );
  }

  removeDoctrosBookings(medStaffId: number, bookingsId: number) {
    const url = `${AppConfig.resourceApiUrl}/api/wsdoctor/get-remove-doctors-bookings`;
    return this.http.post(url, { medStaffId, bookingsId }, { headers: this.addOnHeaders }).pipe(
      map(res => {
        const result = <any[]>res;
        result.forEach(element => {
          element.start = new Date(element.start);
          element.end = new Date(element.end);
        });
        return result;
      })
    );
  }
}
