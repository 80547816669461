import { Component, Input, OnInit } from '@angular/core';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { AnketItemModel } from '../anket-item-model';

@Component({
  selector: 'app-ctrl-vrednie',
  templateUrl: './ctrl-vrednie.component.html',
  styleUrls: ['./ctrl-vrednie.component.scss']
})
export class CtrlVrednieComponent implements OnInit {

  @Input() public disabled: boolean;
  @Input() public dataItem: any;

  public vredniePrivichki = [ "нет", "есть" ];
  public vrednieKur = [ "нет", "есть" ];
  public vrednieAlco = [ "нет", "есть" ];
  public vrednieNarko = [ "нет", "есть" ];
  public vrednieAlcoKakChasto = [ "Эпизодически", "Регулярно" ];
  public spravVrednie = [ "Алкоголизм",
    "Наркомания",
    "Курение",
    "Игровая зависимость",
    "Шопингомания",
    "Переедание",
    "Телевизионная зависимость",
    "Интернет-зависимость",
    "Привычка грызть ногти",
    "Ковыряние кожи",
    "Ковыряние в носу или ринотиллексомания",
    "Щелкание суставами",
    "Техномания",
    "Привычка грызть карандаш или ручку",
    "Привычка сплёвывать на пол",
    "Употребление нецензурных выражений",
    "Злоупотребление «словами-паразитами»" ];

  constructor() { }

  ngOnInit() {

  }

  hasInVariantsSelect(dataObj: any, value: string): boolean {
    if (!dataObj.selected) {
      dataObj.selected = [];
    }

    return dataObj.selected.indexOf(value) > -1;
  }

  varItemClick(dataObj: any, ev) {
    if (this.disabled) {
      return;
    }

    if (!dataObj.selected) {
      dataObj.selected = [];
    }

    const value = ev.target.innerText;

    const idx = dataObj.selected.indexOf(value);
    dataObj.selected = idx > -1 ? [] : [value];
  }

  tryJsonParse(val: string): any {
    try {
      return val ? JSON.parse(val) : null;
    } catch (ex) {
      // console.log('tryJsonParse error', val, ex);
      return null;
    }
  }

  plusPrivychka() {
    if (this.dataItem.anketItem && this.dataItem.anketItem && this.dataItem.anketItem.valueAny && this.dataItem.anketItem.valueAny.plus) {
      this.dataItem.anketItem.valueAny.plus.push({ nazvanie: '', opisanie: '' });
    }
  }

  visVrenieDetails() {
    const v = this.dataItem.anketItem && this.dataItem.anketItem && this.dataItem.anketItem.valueAny 
      && this.dataItem.anketItem.valueAny.privychki && this.dataItem.anketItem.valueAny.privychki.selected
      && this.dataItem.anketItem.valueAny.privychki.selected.length === 1
        ? this.dataItem.anketItem.valueAny.privychki.selected[0]
        : '';

    return v === 'есть';
  }

  visKur() {
    const v = this.dataItem.anketItem && this.dataItem.anketItem && this.dataItem.anketItem.valueAny 
      && this.dataItem.anketItem.valueAny.kur && this.dataItem.anketItem.valueAny.kur.selected
      && this.dataItem.anketItem.valueAny.kur.selected.length === 1
        ? this.dataItem.anketItem.valueAny.kur.selected[0]
        : '';

    return v === 'есть';
  }

  visAlco() {
    const v = this.dataItem.anketItem && this.dataItem.anketItem && this.dataItem.anketItem.valueAny 
      && this.dataItem.anketItem.valueAny.alco && this.dataItem.anketItem.valueAny.alco.selected
      && this.dataItem.anketItem.valueAny.alco.selected.length === 1
        ? this.dataItem.anketItem.valueAny.alco.selected[0]
        : '';

    return v === 'есть';
  }

  visNarko() {
    const v = this.dataItem.anketItem && this.dataItem.anketItem && this.dataItem.anketItem.valueAny 
      && this.dataItem.anketItem.valueAny.narko && this.dataItem.anketItem.valueAny.narko.selected
      && this.dataItem.anketItem.valueAny.narko.selected.length === 1
        ? this.dataItem.anketItem.valueAny.narko.selected[0]
        : '';

    return v === 'есть';
  }

}
