export class AppConfig {
  private static _resourceApiUrl: string;
  private static _identityServerUrl: string;
  private static _wsDoctorUrl: string;

  static get wsDoctorUrl(): string {
    return AppConfig._wsDoctorUrl;
  }

  static get resourceApiUrl(): string {
    return AppConfig._resourceApiUrl;
  }

  static get identityServerUrl(): string {
    return AppConfig._identityServerUrl;
  }

  public static init(resourceApiUrl: string, identityServerUrl: string, wsDoctorUrl: string) {
    AppConfig._resourceApiUrl = resourceApiUrl;
    AppConfig._identityServerUrl = identityServerUrl;
    AppConfig._wsDoctorUrl = wsDoctorUrl;
  }
}
