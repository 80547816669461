import { Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ForUiCombobox } from '../models/ForUiCombobox';

const createFormGroup = (dataItem) =>
    new FormGroup({
      mkb10: new FormControl(dataItem.mkb10, Validators.required),
      comment: new FormControl(dataItem.comment)
    });

@Component({
  selector: 'app-mkb10',
  templateUrl: './mkb10.component.html',
  styleUrls: ['./mkb10.component.css']
})
export class Mkb10Component implements OnInit {
  @ViewChild(GridComponent, {static: false}) private grid: GridComponent;

  public editDataItem: any;

  public formGroup: FormGroup;

  public settingsOpened = false;

  private isNew: boolean;
  private editedRowIndex: number;
  private mkb10EmiasesValueChangedToken: any;

  public virtual: any = {
    itemHeight: 30
  };

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };


  @Input() public userId: number;
  @Input() public disabled: boolean;
  @Input() public model: any[] = [];
  @Input() public allMkb10Emiases: ForUiCombobox[] = [];
  @Input() public inline: boolean;

  public data: any[] = [];

  constructor(
    private renderer: Renderer2,
    ) { }

  ngOnInit() {
    this.data.splice(0, this.data.length);
    if (this.model) {
      this.model.forEach(element => {
        this.data.push(element);
      });
    }

    this.renderer.listen('document', 'dblclick', this.dblClick.bind(this));
  }

  public addHandler({ sender }): void {
    this.closeEditor();

    this.formGroup = createFormGroup({
      mkb10: null,
      comment: ''
    });

    this.isNew = true;
    sender.addRow(this.formGroup);
  }

  public editHandler({sender, dataItem, rowIndex, columnIndex}) {
    this.isNew = false;
    this.formGroup = createFormGroup(dataItem);
    this.editDataItem = dataItem;
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex, this.formGroup, { columnIndex });
  }

  public cancelHandler() {
    this.closeEditor();
  }

  public saveHandler() {
    console.log('saveHandler', this.formGroup.value);
    if (this.isNew) {
      this.model.push(this.formGroup.value);
    } else {
      this.model[this.editedRowIndex] = this.formGroup.value;
    }

    this.editDataItem = undefined;

    this.grid.closeRow(this.editedRowIndex);
  }

  public removeHandler({ rowIndex }) {
    this.model.splice(rowIndex, 1);
  }

  public openTemplSettings() {
    this.settingsOpened = true;
  }

  public settingsCancel() {
    this.settingsOpened = false;
  }

  public mkb10EmiasesValueChanged() {
    this.mkb10EmiasesValueChangedToken = +new Date();
  }

  public settingsSelect(mkb10Id: number) {
    this.settingsCancel();
    this.model.push({ mkb10: mkb10Id });
  }

  public test(ev) {
    console.log('test', ev);
  }

  public getMkb10Name(mkb10Id: any): string {
    const mkb10 = this.allMkb10Emiases.find(x => x.id === mkb10Id);

    if (!mkb10)  {
      return '-';
    }

    return `${mkb10.code}. ${mkb10.name}`;
  }

  public valueChanged() {
    this.model.splice(0, this.model.length);
    this.data.forEach(element => {
      this.model.push(element);
    });
  }

  private closeEditor() {
    this.grid.closeRow(this.editedRowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  private dblClick(e: any): void {
    if (this.mkb10EmiasesValueChangedToken && +new Date() - this.mkb10EmiasesValueChangedToken < 500) {
        this.saveHandler();
    }
  }
}
