import { BrowserModule } from '@angular/platform-browser';
import { Mkb10Component } from './mkb10/mkb10.component';
import { LOCALE_ID, APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { AnketsComponent } from './ankets/ankets.component';
import { SchemasComponent } from './schemas/schemas.component';
import { ParameterTypesComponent } from './parameter-types/parameter-types.component';
import { ControlTypesComponent } from './control-types/control-types.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SchemeItemsComponent } from './schemas/scheme-items/scheme-items.component';
import { SchemeItemEditComponent } from './schemas/scheme-items/scheme-item-edit/scheme-item-edit.component';
import { SchemeEditComponent } from './schemas/scheme-edit/scheme-edit.component';

import { AnketsDataService } from './ankets/data.service';
import { SchemasDataService } from './schemas/data.service';
import { SchemeItemsDataService } from './schemas/scheme-items/data.service';
import { ParameterTypesDataService } from './parameter-types/data.service';
import { ControlTypesDataService } from './control-types/data.service';
import { AppConfigProvider } from './app.config.provider';

import { GridModule } from '@progress/kendo-angular-grid';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { AnketEditComponent } from './ankets/anket-edit/anket-edit.component';
import { AnketsListComponent } from './ankets/ankets-list/ankets-list.component';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { AppWithNavComponent } from './app-with-nav/app-with-nav.component';
import { AncetEditInjectComponent } from './ankets/ancet-edit-inject/ancet-edit-inject.component';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';

import '@progress/kendo-angular-intl/locales/ru/all';
import { JwtInterceptor } from 'src/shared/jwt-interceptor';
import { EncefalitTerritoryComponent } from './encefalit-territory/encefalit-territory.component';
import { KladrRegionService } from './services/kladr/kladr-region.service';
import { KladrDistrictsService } from './services/kladr/kladr-districts.service';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';
import { ScheduleComponent } from './schedule/schedule.component';
import { SchedulePopupComponent } from './schedule/schedule-popup/schedule-popup.component';
import { ProceduresService } from './services/ws/procedures.service';
import { BookingsService } from './services/ws/bookings.service';
import { CertVaccinComponent } from './cert-vaccin/cert-vaccin.component';
import { CertVaccinService } from './cert-vaccin/cert-vaccin.service';
import { DocReceptyComponent } from './doc-recepty/doc-recepty.component';
import { DocReceptyEditComponent } from './doc-recepty/doc-recepty-edit/doc-recepty-edit.component';
import { DocReceptyService } from './doc-recepty/doc-recepty.service';
import { DocSpravkiComponent } from './doc-spravki/doc-spravki.component';
import { DocSpravkiEditComponent } from './doc-spravki/doc-spravki-edit/doc-spravki-edit.component';
import { DocSpravkiService } from './doc-spravki/doc-spravki.service';
import { PatientsService } from './services/ws/patients.service';
import { DocReceptyItemsService } from './doc-recepty/doc-recepty-edit/doc-recepty-items.service';
import { Mkb10TemplSettingsComponent } from './mkb10/mkb10-templ-settings/mkb10-templ-settings.component';
import { FieldTemplateService } from './services/field-template.service';
import { FieldTemplateComponent } from './field-template/field-template.component';
import { MnnComponent } from './mnn/mnn.component';
import { MultyStaffsComponent } from './multy-staffs/multy-staffs.component';
import { MedicalHistoryComponent } from './medical-history/medical-history.component';
import { InnerPerComponent } from './inner-per/inner-per.component';
import { CtrlCheckboxesComponent } from './ankets/ctrl-checkboxes/ctrl-checkboxes.component';
import { CtrlVrednieComponent } from './ankets/ctrl-vrednie/ctrl-vrednie.component';
import { CtrlListyNetrudComponent } from './ankets/ctrl-listy-netrud/ctrl-listy-netrud.component';
import { CtrlPokazOsmotrComponent } from './ankets/ctrl-pokaz-osmotr/ctrl-pokaz-osmotr.component';
import { CtrlLekNaznachComponent } from './ankets/ctrl-lek-naznach/ctrl-lek-naznach.component';
import { CtrlClinOsobComponent } from './ankets/ctrl-clin-osob/ctrl-clin-osob.component';
import { CtrlLimfouzComponent } from './ankets/ctrl-limfouz/ctrl-limfouz.component';
import { CtrlSustavyComponent } from './ankets/ctrl-sustavy/ctrl-sustavy.component';
import { CtrlHripyComponent } from './ankets/ctrl-hripy/ctrl-hripy.component';
import { CtrlStomtableComponent } from './ankets/ctrl-stomtable/ctrl-stomtable.component';
import { CtrlNaznachComponent } from './ankets/ctrl-naznach/ctrl-naznach.component';
import { CtrlNaznachLnComponent } from './ankets/ctrl-naznach-ln/ctrl-naznach-ln.component';
import { CtrlLechenieNaznachComponent } from './ankets/ctrl-lechenie-naznach/ctrl-lechenie-naznach.component';
import { CtrlLechenieMultyNaznachComponent } from './ankets/ctrl-lechenie-multy-naznach/ctrl-lechenie-multy-naznach.component';
import { CtrlLechenieNaznachLnComponent } from './ankets/ctrl-lechenie-naznach-ln/ctrl-lechenie-naznach-ln.component';
import { CtrlDizheniyaComponent } from './ankets/ctrl-dizheniya/ctrl-dizheniya.component';
import { CtrlSuhozhilReflComponent } from './ankets/ctrl-suhozhil-refl/ctrl-suhozhil-refl.component';
import { CtrlChuvstvitelnostComponent } from './ankets/ctrl-chuvstvitelnost/ctrl-chuvstvitelnost.component';
import { CtrlObsledMishcComponent } from './ankets/ctrl-obsled-mishc/ctrl-obsled-mishc.component';
import { ComboboxCustomSourceComponent } from './ankets/combobox-custom-source/combobox-custom-source.component';
import { CtrlIspraznComponent } from './ankets/ctrl-isprazn/ctrl-isprazn.component';
import { CtrlPrimesComponent } from './ankets/ctrl-primes/ctrl-primes.component';
import { CtrlMidriatikComponent } from './ankets/ctrl-midriatik/ctrl-midriatik.component';
import { MessageService } from '@progress/kendo-angular-l10n';
import { WsDocMessageService } from './services/wsdoc-message.service';


export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

export function appConfigProviderFactory(provider: AppConfigProvider) {
  return () => provider.load();
}

// определение внутрисистемных маршрутов
const innerRoutes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'ankets', component: AnketsComponent },
  { path: 'ankets-list/:schemeId', component: AnketsListComponent },
  { path: 'anket-edit', component: AnketEditComponent },
  { path: 'schemas', component: SchemasComponent },
  { path: 'scheme-items/:id', component: SchemeItemsComponent },
  { path: 'parameter-types', component: ParameterTypesComponent },
  { path: 'control-types', component: ControlTypesComponent },
];

const appRoutes: Routes = [
  { path: '', component: AppWithNavComponent},
  { path: '', component: AppWithNavComponent, children: innerRoutes},
  { path: 'anket-edit-inject', component: AncetEditInjectComponent},
  { path: 'cert-vaccin', component: CertVaccinComponent},
  { path: 'doc-spravki', component: DocSpravkiComponent},
  { path: 'doc-recepty', component: DocReceptyComponent}
];

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    AnketsComponent,
    AnketsListComponent,
    AnketEditComponent,
    AncetEditInjectComponent,
    SchemasComponent,
    SchemeEditComponent,
    SchemeItemEditComponent,
    SchemeItemsComponent,
    ParameterTypesComponent,
    ControlTypesComponent,
    AppWithNavComponent,
    Mkb10Component,
    EncefalitTerritoryComponent,
    ScheduleComponent,
    SchedulePopupComponent,
    CertVaccinComponent,
    DocSpravkiComponent,
    DocSpravkiEditComponent,
    DocReceptyComponent,
    DocReceptyEditComponent,
    Mkb10TemplSettingsComponent,
    FieldTemplateComponent,
    MnnComponent,
    CtrlCheckboxesComponent,
    CtrlVrednieComponent,
    CtrlListyNetrudComponent,
    CtrlPokazOsmotrComponent,
    CtrlLekNaznachComponent,
    CtrlClinOsobComponent,
    CtrlLimfouzComponent,
    CtrlSustavyComponent,
    CtrlHripyComponent,
    CtrlStomtableComponent,
    CtrlNaznachComponent,
    CtrlNaznachLnComponent,
    CtrlLechenieNaznachComponent,
    CtrlLechenieMultyNaznachComponent,
    CtrlLechenieNaznachLnComponent,
    CtrlDizheniyaComponent,
    CtrlObsledMishcComponent,
    CtrlSuhozhilReflComponent,
    CtrlChuvstvitelnostComponent,
    ComboboxCustomSourceComponent,
    CtrlIspraznComponent,
    CtrlPrimesComponent,
    CtrlMidriatikComponent,
    MultyStaffsComponent,
    MedicalHistoryComponent,
    InnerPerComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    RouterModule.forRoot(appRoutes),
    ReactiveFormsModule,
    BrowserAnimationsModule,
    GridModule,
    DialogsModule,
    InputsModule,
    LabelModule,
    ButtonsModule,
    TreeListModule,
    DropDownsModule,
    TreeViewModule,
    LayoutModule,
    DateInputsModule,
    SchedulerModule,
  ],
  providers: [
    AnketsDataService,
    SchemasDataService,
    SchemeItemsDataService,
    ParameterTypesDataService,
    ControlTypesDataService,
    AppConfigProvider,
    KladrRegionService,
    KladrDistrictsService,
    ProceduresService,
    BookingsService,
    CertVaccinService,
    DocSpravkiService,
    DocReceptyService,
    DocReceptyItemsService,
    PatientsService,
    FieldTemplateService,
    { provide: LOCALE_ID, useValue: 'ru-RU' },
    { provide: 'BASE_URL', useFactory: getBaseUrl },
    {
      provide: APP_INITIALIZER,
      useFactory: appConfigProviderFactory,
      deps: [AppConfigProvider],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    { provide: MessageService, useClass: WsDocMessageService },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
