import { AnketItemModel } from 'src/app/ankets/anket-item-model';
import { SchemeItemParameterModel } from '../../scheme-item-parameters/scheme-item-parameter-model';

export class SchemeItemEditModel {
  public id: string;
  public schemeId: string;
  public parentId: string;
  public schemeItemName: string;
  public controlTypeId: string;
  public controlTypeCode: number;
  public controlTypeName: string;
  public alias: string;
  public sort: number;

  public parameters: SchemeItemParameterModel[];
  public anyParams: any;
  public data: any[];
  public anketItem: AnketItemModel;
}
