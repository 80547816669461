import { Component, Input, OnInit } from '@angular/core';
import { BookingsService } from '../services/ws/bookings.service';
import * as moment from 'moment';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {

  @Input() public doctorId: number;
  @Input() public patientId: number;
  @Input() public userId: number;
  @Input() public staffList: Array<any>;
  @Input() public disabled: boolean;
  @Input() public model: any = undefined;

  public get time() {
    return moment(this.model.data.time).add(-3, 'hour').format('DD.MM.yyyy HH:mm');
  }

  public schedulePopupIsOpen = false;

  constructor(private bookingsService: BookingsService) { }

  ngOnInit() {
    console.log(123, this.model);
  }

  openSchedulePopup() {
    this.schedulePopupIsOpen = true;
  }

  public cancelHandler() {
    this.schedulePopupIsOpen = false;
  }

  public saveHandler(entity: any) {
    entity.patientId = this.patientId;
    entity.userId = this.userId;
    this.bookingsService.saveDoctrosBookings(entity).subscribe((data) => {
      this.model.data = data;
    });
    this.schedulePopupIsOpen = false;
  }
}
