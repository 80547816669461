import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CancelEvent, EventClickEvent, SchedulerComponent, SlotClickEvent } from '@progress/kendo-angular-scheduler';
import * as moment from 'moment';
import { BookingsService } from 'src/app/services/ws/bookings.service';
import { ProceduresService } from 'src/app/services/ws/procedures.service';

@Component({
  selector: 'app-schedule-popup',
  templateUrl: './schedule-popup.component.html',
  styleUrls: ['./schedule-popup.component.scss']
})
export class SchedulePopupComponent implements OnInit {

  @Input() public doctorId: number;
  @Input() public staffList: Array<any> = [];
  @Input() public data: any = undefined;

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();

  public editActive = false;
  public editId = false;
  public editAdded = false;
  public editStart: any;
  public editEnd: any;
  public disabled = false;

  public virtual: any = {
    itemHeight: 30
  };

  public formGroup: FormGroup;
  public scheduleLoading = false;

  public medStaffId: number;
  public get medStaffIdStr(): string {
    return this.medStaffId.toString();
  }
  public set medStaffIdStr(value) {
    this.medStaffId = value ? parseInt(value, 10) : undefined;
  }
  public procedureId: number;
  public comment: string;

  public procedures: Array<any> = [];
  public doctorsBookings: Array<any> = [];

  public get canSave(): boolean {
    return !!this.medStaffId && !!this.procedureId && this.editAdded;
  }

  constructor(private proceduresService: ProceduresService, private bookingsService: BookingsService, public formBuilder: FormBuilder) { }

  ngOnInit() {
    if (this.data && this.data.data) {
      this.comment = this.data.data.comment;
      this.medStaffId = this.data.data.doctorId;
      this.procedureId = this.data.data.procedureId;

      this.editAdded = true;
      this.editId = this.data.data.id;
      this.editStart = this.data.data.time;
      this.editEnd = moment(this.data.data.time).add(this.data.data.length, 'hour').toDate();
    } else {
      this.medStaffId = this.doctorId;
    }

    this.proceduresService.getProcedures().subscribe((data) => {
      this.procedures = data;
    });
    this.scheduleLoading = true;
    this.bookingsService.getBookingsForDoctor(this.medStaffId).subscribe((data) => {
      this.scheduleLoading = false;
      this.doctorsBookings = data;
    });
  }

  public onSave(e): void {
    e.preventDefault();

    this.save.emit({ medStaffId: this.medStaffId,
      procedureId: this.procedureId,
      comment: this.comment,
      editStart: this.editStart,
      editEnd: this.editEnd
    });
  }

  public closeForm(): void {
      this.cancel.emit();
  }

  public onEditSave(ev): void {
    ev.preventDefault();

    const doctorsBookings = Object.assign([], this.doctorsBookings);

    if (this.editAdded) {
      const ed = doctorsBookings.find(x => !x.id || x.id === this.editId);
      ed.start = this.editStart;
      ed.end = this.editEnd;
    } else {
      doctorsBookings.push({ start: this.editStart, end: this.editEnd });
      this.editAdded = true;
    }

    this.doctorsBookings = doctorsBookings;
    this.editActive = false;
  }

  private closeEditForm(): void {
    this.editActive = false;
  }

  slotDblClickHandler({
    start
  }: SlotClickEvent): void {
    if (this.editStart) {
      return;
    }

    this.editActive = true;
    this.editStart = start;
    this.editEnd = moment(start).add(30, 'minutes').toDate();
  }

  eventDblClickHandler({
    event
  }: EventClickEvent): void {
    if (this.editId && this.editId !== event.id || !this.editId && event.id) {
      return;
    }

    this.editActive = true;
    this.editStart = event.start;
    this.editEnd = event.end;
  }

  removeHandler(ev) {
    this.scheduleLoading = true;
    this.bookingsService.removeDoctrosBookings(this.doctorId, ev.dataItem.id).subscribe((data) => {
      this.doctorsBookings = data;
      this.scheduleLoading = false;
    });
  }

  medStaffChange(ev) {
    if (!this.medStaffId) {
      this.doctorsBookings = [];
      this.editAdded = false;
      return;
    }

    this.scheduleLoading = true;
    this.bookingsService.getBookingsForDoctor(this.medStaffId).subscribe((data) => {
      this.scheduleLoading = false;
      this.doctorsBookings = data;
    });
  }

  test() {
    this.medStaffId = this.doctorId;
  }

  public cancelHandler({ sender }: CancelEvent): void {
    this.closeEditor(sender);
  }

  private closeEditor(scheduler: SchedulerComponent): void {
    scheduler.closeEvent();

    this.formGroup = undefined;
  }
}
