import { Component, Input, Output, EventEmitter, ViewChild, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { DocReceptyItemsService } from './doc-recepty-items.service';

const listCreateFormGroup = (dataItem) =>
    new FormGroup({
      tmpIntId: new FormControl(dataItem.tmpIntId),
      id: new FormControl(dataItem.id),
      data: new FormControl(dataItem.data, Validators.required)
    });

@Component({
  selector: 'app-doc-recepty-edit',
  templateUrl: './doc-recepty-edit.component.html',
  styleUrls: ['./doc-recepty-edit.component.scss']
})
export class DocReceptyEditComponent implements OnInit {
  @ViewChild(GridComponent, {static: false}) private pGrid: GridComponent;

  public active = false;
  public editForm: FormGroup = new FormGroup({
      id: new FormControl(),
      date: new FormControl('', Validators.required),
      patientId: new FormControl(),
      doctorId: new FormControl(),
      isDetskiy: new FormControl(),
      deystvitlen: new FormControl(),
  });

  @Input() public isNew = false;
  @Input() public staffList = [];

  public listView: any[];
  public listFormGroup: FormGroup;

  private listEditedRowIndex: number;
  private listIsNew = false;

  @Input() public set model(entity: any) {
      this.editForm.reset(entity);
      if (entity) {
        this.listService.setItems(entity.items || []);
      }
      this.active = entity !== undefined;
  }

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() print: EventEmitter<any> = new EventEmitter();

  constructor (
    private listService: DocReceptyItemsService,
  ) { }

  ngOnInit(): void {
    this.listView = this.listService.items();
  }

  public onPrint() {
    const recept = (<any>this.editForm).value;
    recept.items = this.listView;
    this.print.emit(recept);
  }

  public onSave(e): void {
      e.preventDefault();
      const recept = (<any>this.editForm).value;
      recept.items = this.listView;
      this.save.emit(recept);
      this.active = false;
  }

  public onCancel(e): void {
      e.preventDefault();
      this.closeForm();
  }

  public listAddHandler({ sender }): void {
    this.listCloseEditor(sender);

    this.listFormGroup = listCreateFormGroup({
      id: null,
      data: ''
    });
    this.listIsNew = true;

    this.pGrid.addRow(this.listFormGroup);
  }

  private listCloseEditor(grid, rowIndex = this.listEditedRowIndex) {
    grid.closeRow(rowIndex);
    this.listEditedRowIndex = undefined;
    this.listFormGroup = undefined;
  }

  public listEditHandler({ sender, rowIndex, dataItem }) {
    this.listCloseEditor(sender);

    this.listFormGroup = listCreateFormGroup(dataItem);

    this.listEditedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.listFormGroup);
  }

  public listCancelHandler({ sender, rowIndex }) {
    this.listCloseEditor(sender, rowIndex);
  }

  public listSaveHandler({ sender, rowIndex, formGroup, isNew }) {
    const listItem: any = formGroup.value;

    this.listService.save(listItem, isNew);

    sender.closeRow(rowIndex);
  }

  public listRemoveHandler({ dataItem }) {
    this.listService.remove(dataItem);
  }

  private closeForm(): void {
      this.active = false;
      this.cancel.emit();
  }
}
