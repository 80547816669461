import { Component, Input, OnInit } from '@angular/core';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { AnketItemModel } from '../anket-item-model';
import { AnketsDataService } from '../data.service';

@Component({
  selector: 'app-ctrl-lechenie-naznach-ln',
  templateUrl: './ctrl-lechenie-naznach-ln.component.html',
  styleUrls: ['./ctrl-lechenie-naznach-ln.component.scss']
})
export class CtrlLechenieNaznachLnComponent implements OnInit {

  @Input() public disabled: boolean;
  @Input() public dataItem: any;
  @Input() public allMnnOtn: any[] = [];

  public virtual: any = {
    itemHeight: 30
  };

  public nazFormaPrim = [ "перорально", "сублингвально", "ингаляции", "подкожно", "внутримышечно", "внутривенно", "внутривагинально", "внутриректально", "другое" ];
  public vrednieKur = [ "нет", "есть" ];
  public vrednieAlco = [ "нет", "есть" ];
  public vrednieNarko = [ "нет", "есть" ];
  public vrednieAlcoKakChasto = [ "Эпизодически", "Регулярно" ];
  public spravVrednie = [ "Алкоголизм",
    "Наркомания",
    "Курение",
    "Игровая зависимость",
    "Шопингомания",
    "Переедание",
    "Телевизионная зависимость",
    "Интернет-зависимость",
    "Привычка грызть ногти",
    "Ковыряние кожи",
    "Ковыряние в носу или ринотиллексомания",
    "Щелкание суставами",
    "Техномания",
    "Привычка грызть карандаш или ручку",
    "Привычка сплёвывать на пол",
    "Употребление нецензурных выражений",
    "Злоупотребление «словами-паразитами»" ];

    constructor(
      private dataService: AnketsDataService,
    ) { }
  
    ngOnInit() {
      this.fillRecs(1);
    }
  
    fillRecs(p) {
      this.dataService.getCustomComboboxData('wsdoctor/get-mnn-otn-list', p).subscribe(
        (data) => {
          if (data.length < 1) {
            return;
          }

          this.allMnnOtn = this.allMnnOtn.concat(data.map(x => {
            return { text: x.text, value: x.id.toString(), name: x.name };
          }));
          this.fillRecs(++p);
        });
    }

  hasInVariantsSelect(dataObj: any, value: string): boolean {
    if (!dataObj.selected) {
      dataObj.selected = [];
    }

    return dataObj.selected.indexOf(value) > -1;
  }

  varItemClick(dataObj: any, ev) {
    if (this.disabled) {
      return;
    }

    if (!dataObj.selected) {
      dataObj.selected = [];
    }

    const value = ev.target.innerText;

    const idx = dataObj.selected.indexOf(value);
    dataObj.selected = idx > -1 ? [] : [value];
  }

  tryJsonParse(val: string): any {
    try {
      return val ? JSON.parse(val) : null;
    } catch (ex) {
      // console.log('tryJsonParse error', val, ex);
      return null;
    }
  }

  plusNaz() {
    if (this.dataItem.anketItem && this.dataItem.anketItem && this.dataItem.anketItem.valueAny && this.dataItem.anketItem.valueAny.plus) {
      this.dataItem.anketItem.valueAny.plus.push({ mnnOtn: [], formaPrim: {} });
    }
  }

  visVrenieDetails() {
    const v = this.dataItem.anketItem && this.dataItem.anketItem && this.dataItem.anketItem.valueAny 
      && this.dataItem.anketItem.valueAny.privychki && this.dataItem.anketItem.valueAny.privychki.selected
      && this.dataItem.anketItem.valueAny.privychki.selected.length === 1
        ? this.dataItem.anketItem.valueAny.privychki.selected[0]
        : '';

    return v === 'есть';
  }

  visKur() {
    const v = this.dataItem.anketItem && this.dataItem.anketItem && this.dataItem.anketItem.valueAny 
      && this.dataItem.anketItem.valueAny.kur && this.dataItem.anketItem.valueAny.kur.selected
      && this.dataItem.anketItem.valueAny.kur.selected.length === 1
        ? this.dataItem.anketItem.valueAny.kur.selected[0]
        : '';

    return v === 'есть';
  }

  visAlco() {
    const v = this.dataItem.anketItem && this.dataItem.anketItem && this.dataItem.anketItem.valueAny 
      && this.dataItem.anketItem.valueAny.alco && this.dataItem.anketItem.valueAny.alco.selected
      && this.dataItem.anketItem.valueAny.alco.selected.length === 1
        ? this.dataItem.anketItem.valueAny.alco.selected[0]
        : '';

    return v === 'есть';
  }

  visNarko() {
    const v = this.dataItem.anketItem && this.dataItem.anketItem && this.dataItem.anketItem.valueAny 
      && this.dataItem.anketItem.valueAny.narko && this.dataItem.anketItem.valueAny.narko.selected
      && this.dataItem.anketItem.valueAny.narko.selected.length === 1
        ? this.dataItem.anketItem.valueAny.narko.selected[0]
        : '';

    return v === 'есть';
  }

}
