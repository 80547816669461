import { Component, EventEmitter, NgZone, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExpandEvent } from '@progress/kendo-angular-treelist';
import { SchemeEditModel } from 'src/app/schemas/scheme-edit-model';
import { SchemeItemsDataService } from 'src/app/schemas/scheme-items/data.service';
import { SchemeItemEditModel } from 'src/app/schemas/scheme-items/scheme-item-edit/scheme-item-edit-model';
import { AnketItemModel } from '../anket-item-model';
import { AnketModel } from '../anket-model';
import { AnketsDataService } from '../data.service';
import { ValueAddOnModel } from '../value-addon-model';

@Component({
  selector: 'app-anket-edit',
  templateUrl: './anket-edit.component.html',
  styleUrls: ['./anket-edit.component.scss']
})
export class AnketEditComponent implements OnInit, OnDestroy {

  public anketId: string;
  public schemeId: string;
  public schemeAlias: string;
  public schemeName: string;
  public isNew: boolean;

  public anket: AnketModel;

  public doctorId: number;
  public patientId: number;

  @Output() hideAppNav = new EventEmitter();

  public get scheme(): SchemeEditModel {
    return this.anket && this.anket.schemeVersion
      ? this.anket.schemeVersion.scheme
      : null;
  }

  public get anketItemForm(): AnketItemModel {
    return this.selectedIds.length > 0
      ? this.anket.anketItems.filter(x => x.schemeItemId === this.selectedIds[0])[0]
      : null;
  }

  public selectedIds: string[] = [];
  public expandedIds: string[] = [];

  private parsAnketScrollSubscription: any;

  public get items(): SchemeItemEditModel[] {
    return this.anket && this.anket.schemeVersion && this.anket.schemeVersion.scheme
      ? this.anket.schemeVersion.scheme.schemeItems.sort((a, b) => a.sort - b.sort)
      : [];
  }

  constructor(
    private schemeItemsDataService: SchemeItemsDataService,
    private anketsDataService: AnketsDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(
      (queryParam: any) => {
          this.anketId = queryParam['id'];
          this.schemeId = queryParam['schemeId'];
          this.schemeAlias = queryParam['schemeAlias'];
          this.doctorId = queryParam['doctorId'];
          this.patientId = queryParam['patientId'];
          this.isNew = !this.anketId;
      }
    );

    this.anketsDataService.get(this.doctorId, this.patientId, this.schemeId, this.schemeAlias, this.anketId).subscribe({ next: anket => {
      this.anket = anket;
      this.anketAddOnValueFill(anket.anketItems);
      this.expandedIds = this.items.map(x => x.id);
    }});

    this.parsAnketScrollSubscription = this.renderer.listen('document', 'wheel', this.parsOnAnketScroll.bind(this));

    (<any>window).inner = (<any>window).inner || {};
    (<any>window).inner.publicFunc2 = this.publicFunc2.bind(this);
    (<any>window).inner.publicFunc3 = this.publicFunc3.bind(this);
  }

  ngOnDestroy() {
    this.parsAnketScrollSubscription();

    (<any>window).inner.publicFunc2 = null;
    (<any>window).inner.publicFunc3 = null;
  }

  parsOnAnketScroll(e: any): void {
    if (this.items.length === 0 || e.deltaY === 0) {
      return;
    }

    let newId = null;
    if (this.selectedIds.length === 0) {
      if (e.deltaY > 0) {
        newId = this.items[0].id;
        this.selectedIds = [];
      } else {
        const firstLvlItems = this.items.filter(x => !x.parentId);
        const lastFirstLvlItem = firstLvlItems[firstLvlItems.length - 1];
        const lastFirstLvlItemLastChildTree = this.getLastChildTree(lastFirstLvlItem);
        this.selectedIds = [lastFirstLvlItemLastChildTree ? lastFirstLvlItemLastChildTree.id : lastFirstLvlItem.id];
      }
    } else {
      const old = this.items.filter(x => x.id === this.selectedIds[0])[0];
      const parent = old.parentId ? this.items.filter(x => x.id === old.parentId)[0] : null;
      const parentSibls = (() => {
        if (!parent) {
          return null;
        }

        const parentParent = parent.parentId ? this.items.filter(x => x.id === parent.parentId)[0] : null;
        return parentParent ? this.items.filter(x => x.parentId === parentParent.id) : this.items.filter(x => !x.parentId);
      })();
      const parentIdx = parentSibls ? parentSibls.map(x => x.id).indexOf(parent.id) : -1;

      const sibls = parent ? this.items.filter(x => x.parentId === parent.id) : this.items.filter(x => !x.parentId);
      const oldIdx = sibls.map(x => x.id).indexOf(old.id);

      if (e.deltaY < 0) {
        if (oldIdx > 0) {
          const prevSibl = sibls[oldIdx - 1];
          const prevSiblLastChildTree = this.getLastChildTree(prevSibl);
          newId = prevSiblLastChildTree ? prevSiblLastChildTree.id : sibls[oldIdx - 1].id;
        } else if (parent) {
          newId = parent.id;
        }
      } else {
        const oldChilds = this.items.filter(x => x.parentId === old.id);
        if (oldChilds.length > 0) {
          newId = oldChilds[0].id;
        } else if (oldIdx < sibls.length - 1) {
          newId = sibls[oldIdx + 1].id;
        } else if (parent) {
          if (parentIdx < parentSibls.length - 1) {
            newId = parentSibls[parentIdx + 1].id;
          }
        }
      }
    }

    if (!newId) {
      return;
    }

    this.SetExpandedRecursive(newId);

    this.selectedIds = [newId];
  }

  private SetExpandedRecursive(itemId: string) {
    if (this.expandedIds.indexOf(itemId) === -1) {
      this.expandedIds.push(itemId);
    }

    const parentId = this.items.find(x => x.id === itemId).parentId;
    if (parentId) {
      this.SetExpandedRecursive(parentId);
    }
  }

  private getLastChildTree(item: SchemeItemEditModel): SchemeItemEditModel {
    const itemChilds = this.items.filter(x => x.parentId === item.id);
    if (itemChilds.length < 1) {
      return null;
    }

    const lastItemChild = itemChilds[itemChilds.length - 1];
    const lastChildTree = this.getLastChildTree(lastItemChild);
    return lastChildTree ? lastChildTree : lastItemChild;
  }

  publicFunc2() {
    this.ngZone.run(() => this.privateFunc2());
  }

  publicFunc3(callback) {
    this.ngZone.run(() => callback(this.privateFunc3()));
  }

  privateFunc2() {
    this.save(true);
  }

  privateFunc3() {
    return this.anket;
  }

  anketAddOnValueFill(anketItems: AnketItemModel[]) {
    if (!anketItems) {
      return;
    }

    for (let i = 0; i < anketItems.length; i++) {
      anketItems[i].schemeItem = this.getSchemeItem(anketItems[i]);
      AnketItemModel.addOnFill(anketItems[i]);
    }
  }

  anketAddOnValueReFill(anketItems: AnketItemModel[]) {
    if (!anketItems) {
      return;
    }

    for (let i = 0; i < anketItems.length; i++) {
      AnketItemModel.addOnReFill(anketItems[i]);
    }
  }

  nodeClick(ev) {
    // console.log('nodeClick', ev);
  }

  getSchemeItem(anketItem: AnketItemModel): SchemeItemEditModel {
    if (!this.scheme || !this.scheme.schemeItems) {
      return null;
    }

    const result = this.scheme.schemeItems.filter(x => x.id === anketItem.schemeItemId);
    return result.length === 1 ? result[0] : null;
  }

  varItemClick(ev) {
    AnketItemModel.toggleVariantsSelect(this.anketItemForm, ev.target.innerText);
  }

  isExpanded = (dataItem: any): boolean => {
    return this.expandedIds.indexOf(dataItem.id) > -1;
  }

  onCollapse(args: ExpandEvent): void {
      this.expandedIds = this.expandedIds.filter(id => id !== args.dataItem.id);
  }

  onExpand(args: ExpandEvent): void {
      this.expandedIds.push(args.dataItem.id);
  }

  save(needContinue: boolean) {
    this.anketAddOnValueReFill(this.anket.anketItems);
    this.anketsDataService.save(this.anket).subscribe((anket) => {
      if (needContinue) {
        this.router.navigate(['/anket-edit/', this.anket.schemeVersion.schemeId], { queryParams: { id: anket.id } });
      } else {
        this.router.navigate(['/ankets-list/', this.anket.schemeVersion.schemeId]);
      }
    });
  }

  hasInVariantsSelect(anketItem: AnketItemModel, value: string): boolean {
    return anketItem.valueAddOn.selected.indexOf(value) > -1;
  }

  valueAddOnResult(valueAddOn: ValueAddOnModel): string {
    return [ valueAddOn.selected.join(', '), valueAddOn.other ].filter(x => x != null && x !== '').join(', ');
  }

  test(obj) {
    console.log('test', obj);
  }
}
