import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { State } from '@progress/kendo-data-query';

import { SchemasDataService } from './data.service';
import { SchemeEditModel } from './scheme-edit-model';

@Component({
    selector: 'app-schemas',
    templateUrl: './schemas.component.html'
})
export class SchemasComponent implements OnInit {

  public view: Observable<any>;
  public gridState: any = {
      sort: [],
      skip: 0,
      take: 100
  };

  public editDataItem: SchemeEditModel;
  public isNew: boolean;

  constructor(private dataService: SchemasDataService) {

  }

  public ngOnInit(): void {
    this.view = this.dataService;
    this.dataService.read();
  }

  public onStateChange(state: State): void {
    this.gridState = state;
    this.dataService.read();
  }

  public addHandler() {
    this.editDataItem = new SchemeEditModel();
    this.isNew = true;
  }

  public editHandler({dataItem}) {
      this.editDataItem = dataItem;
      this.isNew = false;
  }

  public cancelHandler() {
    this.editDataItem = undefined;
  }

  public saveHandler(entity: SchemeEditModel) {
    this.dataService.save(entity, this.isNew);
    this.editDataItem = undefined;
  }

  public removeHandler({ dataItem }) {
      this.dataService.remove(dataItem.id);
  }
}
