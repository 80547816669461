import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-multy-staffs',
  templateUrl: './multy-staffs.component.html',
  styleUrls: ['./multy-staffs.component.css']
})
export class MultyStaffsComponent implements OnInit {

  @Input() public staffList: Array<any>;
  @Input() public disabled: boolean;
  @Input() public styles: string;

  private _model: number[];

  @Input() public set model(value: number[]) {
    this._model = value;

    this.data = [];
    this._model.forEach((el) => {
      this.data.push({ doctorId: el });
    });

    this.data.push({ doctorId: undefined });
  }

  public data: any[];

  public virtual: any = {
    itemHeight: 30
  };

  constructor() { }

  ngOnInit() {
  }

  public onChange() {
    this.data = this.data.filter(x => !!x.doctorId);

    const m = this._model;
    m.length = 0;
    this.data.forEach(el => m.push(el.doctorId));

    this.data.push({ doctorId: undefined });
  }

  public test() {
    console.log('test 1', this._model);
    console.log('test 2', this.model);
    console.log('test 3', this.data);
  }

}
