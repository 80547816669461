import { JsonpClientBackend } from '@angular/common/http';
import { SchemeItemEditModel } from '../schemas/scheme-items/scheme-item-edit/scheme-item-edit-model';
import { ValueAddOnModel } from './value-addon-model';

export class AnketItemModel {
  public id: string;
  public anketId: string;
  public schemeItemId: string;
  public value: string;
  public valueDigit: number;
  public valueAny: any;
  public styles: string;

  public schemeItem: SchemeItemEditModel;

  public variants: string[];

  public valueAddOn: ValueAddOnModel;

  public static addOnFill(anketItem: AnketItemModel) {
    if (anketItem.schemeItem.controlTypeCode === 30) {
      anketItem.valueDigit = anketItem.value ? parseInt(anketItem.value, 10) : 0;
      return;
    }

    if (anketItem.schemeItem.controlTypeCode === 40) {
      if (!anketItem.value) {
        anketItem.value = null;
      }
      anketItem.valueAddOn = new ValueAddOnModel();

      if (anketItem.schemeItem.anyParams && anketItem.schemeItem.anyParams.addOnJson
        && anketItem.schemeItem.anyParams.addOnJson.multiselect) {
        anketItem.valueAny = anketItem.value ? JSON.parse(anketItem.value) : [];
      }
      return;
    }

    if (anketItem.schemeItem.controlTypeCode === 50 || anketItem.schemeItem.controlTypeCode === 60) {
      const addOnJsonPar = anketItem.schemeItem.parameters.find(x => x.parameterType.code === 80);
      if (addOnJsonPar) {
        const addOnJson = JSON.parse(addOnJsonPar.value);
        if (addOnJson && addOnJson.dopDateTime) {
          if (!anketItem.valueAddOn) {
            anketItem.valueAddOn = new ValueAddOnModel();
          }
          const valObj = JSON.parse(anketItem.value);
          if (valObj && valObj.dopDateTime) {
            anketItem.valueAddOn.dopDateTimeDate = new Date(valObj.dopDateTime);
          }
        }

        if (addOnJson) {
          if (addOnJson.ctrls === 'checkboxes') {    
            const parVars = anketItem.schemeItem.parameters.filter(x => x.parameterType.code === 50);
            if (parVars.length === 1) {
              anketItem.variants = parVars[0].value.split('|');
            } else {
              anketItem.variants = [];
            }
            
            if (anketItem.value) {
              anketItem.valueAny = JSON.parse(anketItem.value);
              if (addOnJson.dops && (!anketItem.valueAny.dops || anketItem.valueAny.dops.length < addOnJson.dops.length)) {
                anketItem.valueAny.dops = [];
                const d = addOnJson.dops.length - anketItem.valueAny.dops.length;
                for (let i = 0; i < d; i++) {
                  anketItem.valueAny.dops.push({});
                }
              }
            } else {
              anketItem.valueAny = { selected: [], dops: [] };
              if (addOnJson.dops) {
                for (let i = 0; i < addOnJson.dops.length; i++) {
                  anketItem.valueAny.dops.push({});
                }
              }
            }

            return;
          }
          if (addOnJson.ctrls === 'vrednie') {      
            if (anketItem.value) {
              anketItem.valueAny = JSON.parse(anketItem.value);
            } else {
              anketItem.valueAny = { privychki: {}, kur: {}, alco: {}, alcoKakChasto: {}, narko: {}, plus: [] };
            }

            return;
          }
          if (addOnJson.ctrls === 'pokaz-osmotr') {
            anketItem.valueAny = { };
            return;
          }
          if (addOnJson.ctrls === 'listy-netrud') {      
            if (anketItem.value) {
              anketItem.valueAny = JSON.parse(anketItem.value);
              if (anketItem.valueAny.listy && anketItem.valueAny.listy.find(x => !x.mkb)) {
                anketItem.valueAny.listy.filter(x => !x.mkb).forEach(element => {
                  element.mkb = [];
                });
              }
              anketItem.valueAny.listy.forEach(element => {
                if (element.dateFrom) {
                  element.dateFrom = new Date(element.dateFrom);
                }
                if (element.dateTo) {
                  element.dateTo = new Date(element.dateTo);
                }
              });
            } else {
              anketItem.valueAny = { varianty: {}, listy: [ { mkb: [] } ] };
            }

            return;
          }
          if (addOnJson.ctrls === 'lek-naznach') {      
            if (anketItem.value) {
              anketItem.valueAny = JSON.parse(anketItem.value);
              if (anketItem.valueAny.plus && anketItem.valueAny.plus.find(x => !x.mnnOtn)) {
                anketItem.valueAny.plus.filter(x => !x.mnnOtn).forEach(element => {
                  element.mnnOtn = [];
                });
              }
              if (anketItem.valueAny.plus && anketItem.valueAny.plus.find(x => !x.formaPrim)) {
                anketItem.valueAny.plus.filter(x => !x.formaPrim).forEach(element => {
                  element.formaPrim = {};
                });
              }

            } else {
              anketItem.valueAny = { formaPrim: {}, plus: [ { mnnOtn: [], formaPrim: {} } ] };
            }

            return;
          }
          if (addOnJson.ctrls === 'clin-osob') {      
            if (anketItem.value) {
              anketItem.valueAny = JSON.parse(anketItem.value);
            } else {
              anketItem.valueAny = { varianty: {}, plus: [ { var: {} } ] };
            }

            return;
          }
          if (addOnJson.ctrls === 'limfouz') {      
            if (anketItem.value) {
              anketItem.valueAny = JSON.parse(anketItem.value);
            } else {
              anketItem.valueAny = { varianty: {}, plus: [ { var1: {}, var2: {}, var3: {}, var4: {}, var5: {} } ] };
            }

            return;
          }
          if (addOnJson.ctrls === 'sustavy') {      
            if (anketItem.value) {
              anketItem.valueAny = JSON.parse(anketItem.value);
            } else {
              anketItem.valueAny = { varianty: {}, plus: [ { var1: {}, var2: {}, var3: {}, var4: {}, var5: {}, var6: {} } ] };
            }

            return;
          }
          if (addOnJson.ctrls === 'hripy') {      
            if (anketItem.value) {
              anketItem.valueAny = JSON.parse(anketItem.value);
            } else {
              anketItem.valueAny = { varianty: {}, plus: [ { var1: {}, var2: {}, var3: {}, var4: {}, var5: {}, var6: {} } ] };
            }

            return;
          }
          if (addOnJson.ctrls === 'stomtable') {      
            if (anketItem.value) {
              anketItem.valueAny = JSON.parse(anketItem.value);
            } else {
              anketItem.valueAny = { varianty: {}, plus: [ { var1: {}, var2: {}, var3: {}, var4: {}, var5: {}, var6: {} } ] };
            }

            return;
          }
          if (addOnJson.ctrls === 'naznach') {      
            if (anketItem.value) {
              anketItem.valueAny = JSON.parse(anketItem.value);
            } else {
              anketItem.valueAny = { varianty: {}, plus: [ { var1: {}, var2: {}, var3: {}, var4: {}, var5: {}, var6: {} } ] };
            }

            return;
          }
          if (addOnJson.ctrls === 'lechenie-multy-naznach') {      
            if (anketItem.value) {
              anketItem.valueAny = JSON.parse(anketItem.value);
              
              if (anketItem.valueAny.plusPk && anketItem.valueAny.plusPk.find(x => !x.mnnOtn)) {
                anketItem.valueAny.plusPk.filter(x => !x.mnnOtn).forEach(element => {
                  element.mnnOtn = [];
                });
              }
              if (anketItem.valueAny.plusPk && anketItem.valueAny.plusPk.find(x => !x.formaPrim)) {
                anketItem.valueAny.plusPk.filter(x => !x.formaPrim).forEach(element => {
                  element.formaPrim = {};
                });
              }

              if (anketItem.valueAny.plusFl && anketItem.valueAny.plusFl.find(x => !x.mnnOtn)) {
                anketItem.valueAny.plusFl.filter(x => !x.mnnOtn).forEach(element => {
                  element.mnnOtn = [];
                });
              }
              if (anketItem.valueAny.plusFl && anketItem.valueAny.plusFl.find(x => !x.formaPrim)) {
                anketItem.valueAny.plusFl.filter(x => !x.formaPrim).forEach(element => {
                  element.formaPrim = {};
                });
              }
              
              if (anketItem.valueAny.plusIm && anketItem.valueAny.plusIm.find(x => !x.mnnOtn)) {
                anketItem.valueAny.plusIm.filter(x => !x.mnnOtn).forEach(element => {
                  element.mnnOtn = [];
                });
              }
              if (anketItem.valueAny.plusIm && anketItem.valueAny.plusIm.find(x => !x.formaPrim)) {
                anketItem.valueAny.plusIm.filter(x => !x.formaPrim).forEach(element => {
                  element.formaPrim = {};
                });
              }

            } else {
              anketItem.valueAny = { formaPrim: {}, z1: {}, plusPk: [ { mnnOtn: [], formaPrim: {} } ], plusFl: [ { mnnOtn: [], formaPrim: {} } ], plusIm: [ { mnnOtn: [], formaPrim: {} } ] };
            }

            return;
          }
          if (addOnJson.ctrls === 'lechenie-naznach') {      
            if (anketItem.value) {
              anketItem.valueAny = JSON.parse(anketItem.value);

              if (anketItem.valueAny.plusPk && anketItem.valueAny.plusPk.find(x => !x.mnnOtn)) {
                anketItem.valueAny.plusPk.filter(x => !x.mnnOtn).forEach(element => {
                  element.mnnOtn = [];
                });
              }
              if (anketItem.valueAny.plusPk && anketItem.valueAny.plusPk.find(x => !x.formaPrim)) {
                anketItem.valueAny.plusPk.filter(x => !x.formaPrim).forEach(element => {
                  element.formaPrim = {};
                });
              }

              if (anketItem.valueAny.plusFl && anketItem.valueAny.plusFl.find(x => !x.mnnOtn)) {
                anketItem.valueAny.plusFl.filter(x => !x.mnnOtn).forEach(element => {
                  element.mnnOtn = [];
                });
              }
              if (anketItem.valueAny.plusFl && anketItem.valueAny.plusFl.find(x => !x.formaPrim)) {
                anketItem.valueAny.plusFl.filter(x => !x.formaPrim).forEach(element => {
                  element.formaPrim = {};
                });
              }
              
              if (anketItem.valueAny.plusIm && anketItem.valueAny.plusIm.find(x => !x.mnnOtn)) {
                anketItem.valueAny.plusIm.filter(x => !x.mnnOtn).forEach(element => {
                  element.mnnOtn = [];
                });
              }
              if (anketItem.valueAny.plusIm && anketItem.valueAny.plusIm.find(x => !x.formaPrim)) {
                anketItem.valueAny.plusIm.filter(x => !x.formaPrim).forEach(element => {
                  element.formaPrim = {};
                });
              }

            } else {
              anketItem.valueAny = { formaPrim: {}, z1: {}, plusPk: [ { mnnOtn: [], formaPrim: {} } ], plusFl: [ { mnnOtn: [], formaPrim: {} } ], plusIm: [ { mnnOtn: [], formaPrim: {} } ] };
            }

            return;
          }
          if (addOnJson.ctrls === 'dizheniya') {      
            if (anketItem.value) {
              anketItem.valueAny = JSON.parse(anketItem.value);
            } else {
              anketItem.valueAny = { varianty: {}, plus: [ { var1: {}, var2: {}, var3: {}, var4: {}, var5: {}, var6: {} } ] };
            }

            return;
          }
          if (addOnJson.ctrls === 'obsled-mishc') {      
            if (anketItem.value) {
              anketItem.valueAny = JSON.parse(anketItem.value);
            } else {
              anketItem.valueAny = { varianty: {}, plus: [ { var1: {}, var2: {}, var3: {}, var4: {}, var5: {}, var6: {} } ] };
            }

            return;
          }
          if (addOnJson.ctrls === 'suhozhil-refl') {      
            if (anketItem.value) {
              anketItem.valueAny = JSON.parse(anketItem.value);
            } else {
              anketItem.valueAny = { varianty: {}, plus: [ { var1: {}, var2: {}, var3: {}, var4: {}, var5: {}, var6: {} } ] };
            }

            return;
          }
          if (addOnJson.ctrls === 'chuvstvitelnost') {      
            if (anketItem.value) {
              anketItem.valueAny = JSON.parse(anketItem.value);
            } else {
              anketItem.valueAny = { varianty: {}, plus: [ { var1: {}, var2: {}, var3: {}, var4: {}, var5: {}, var6: {} } ] };
            }

            return;
          }
          if (addOnJson.ctrls === 'primes') {      
            if (anketItem.value) {
              anketItem.valueAny = JSON.parse(anketItem.value);
            } else {
              anketItem.valueAny = { varianty: {}, plus: [ { var1: {}, var2: {}, var3: {}, var4: {}, var5: {}, var6: {} } ] };
            }

            return;
          }
          if (addOnJson.ctrls === 'isprazn') {      
            if (anketItem.value) {
              anketItem.valueAny = JSON.parse(anketItem.value);
            } else {
              anketItem.valueAny = { varianty: {}, plus: [ { var1: {}, var2: {}, var3: {}, var4: {}, var5: {}, var6: {} } ] };
            }

            return;
          }
          if (addOnJson.ctrls === 'midriatik') {      
            if (anketItem.value) {
              anketItem.valueAny = JSON.parse(anketItem.value);
            } else {
              anketItem.valueAny = { varianty: {}, plus: [ { var1: {}, var2: {}, var3: {}, var4: {}, var5: {}, var6: {} } ] };
            }

            return;
          }
        }

        if (addOnJson && addOnJson.checks) {
          if (!anketItem.valueAddOn) {
            anketItem.valueAddOn = new ValueAddOnModel();
          }
          anketItem.valueAddOn.checksModel = (<any[]>addOnJson.checks.items).map(x => ({ id: x.id, selected: false,
            dopSimples: x.dopAnySimples && x.dopAnySimples.items
            ? x.dopAnySimples.items.filter(y => !!y.id).map(y => ({ id: y.id, value: null }))
            : [] }));
          const valObj = JSON.parse(anketItem.value);
          if (valObj && valObj.checksModel) {
            (<any[]>valObj.checksModel).forEach(el => {
              const valObjItm = anketItem.valueAddOn.checksModel.find(x => x.id === el.id);
              if (valObjItm) {
                if (el.selected) {
                  valObjItm.selected = true;
                }
                if (el.dopTextBox) {
                  valObjItm.dopTextBox = el.dopTextBox;
                }
                if (el.dopSimples && valObjItm.dopSimples) {
                  valObjItm.dopSimples.forEach(dopEl => {
                    const elDopEl = el.dopSimples.find(x => x.id === dopEl.id);
                    if (elDopEl) {
                      dopEl.value = elDopEl.value;
                    }
                  });
                }
              }
            });
          }

          const aojParVars = anketItem.schemeItem.parameters.filter(x => x.parameterType.code === 50);
          if (aojParVars.length === 1) {
            anketItem.variants = aojParVars[0].value.split('|');
          } else {
            anketItem.variants = [];
          }

          return;
        }
      }

      if (anketItem.schemeItem.parameters.filter(x => x.parameterType.code === 50).length !== 1) {
        if (!anketItem.value || anketItem.value.indexOf("\"dop") < 0) {
          if (!anketItem.valueAddOn) {
            anketItem.valueAddOn = new ValueAddOnModel();
          }
          return;
        }
      }

      const vObj = JSON.parse(anketItem.value);

      anketItem.valueAddOn = vObj ? <ValueAddOnModel>vObj : new ValueAddOnModel();

      const parVars = anketItem.schemeItem.parameters.filter(x => x.parameterType.code === 50);
      if (parVars && parVars.length === 1) {
        anketItem.variants = parVars[0].value.split('|');
      } else {
        anketItem.variants = [];
      }

      if (vObj && vObj.dopDateTime) {
        anketItem.valueAddOn.dopDateTimeDate = new Date(vObj.dopDateTime);
      }
    }

    if (anketItem.schemeItem.controlTypeCode === 70) {
      if (anketItem.value) {
        anketItem.valueAny = new Date(anketItem.value);
      }
      return;
    }

    if (anketItem.schemeItem.controlTypeCode === 80) {
      if (anketItem.value) {
        anketItem.valueAny = JSON.parse(anketItem.value);
      } else {
        anketItem.valueAny = [];
      }
      return;
    }

    if (anketItem.schemeItem.controlTypeCode === 90 || anketItem.schemeItem.controlTypeCode === 100) {
      if (anketItem.value) {
        anketItem.valueAny = JSON.parse(anketItem.value);
        if (anketItem.valueAny.date) {
          anketItem.valueAny.date = new Date(anketItem.valueAny.date);
        }
      } else {
        anketItem.valueAny = {};
      }
      return;
    }

    if (anketItem.schemeItem.controlTypeCode === 110) {
      if (anketItem.value) {
        anketItem.valueAny = JSON.parse(anketItem.value);
      } else {
        anketItem.valueAny = [];
      }
      return;
    }

    if (anketItem.schemeItem.controlTypeCode === 130) {
      if (anketItem.value) {
        anketItem.valueAny = JSON.parse(anketItem.value);
      } else {
        anketItem.valueAny = [{}, {}, {}, {}, {}];
      }
      return;
    }
  }

  public static addOnReFill(anketItem: AnketItemModel): boolean {
    if (!anketItem.schemeItem) {
      return false;
    }

    const controlTypeCode = anketItem.schemeItem.controlTypeCode;

    if (controlTypeCode === 30) {
      anketItem.value = anketItem.valueDigit ? anketItem.valueDigit.toString() : '';
      return true;
    }

    if (controlTypeCode === 40 && anketItem.schemeItem.anyParams && anketItem.schemeItem.anyParams.addOnJson
      && anketItem.schemeItem.anyParams.addOnJson.multiselect) {
      anketItem.value = JSON.stringify(anketItem.valueAny);
      return true;
    }

    if (controlTypeCode === 70) {
      if (anketItem.valueAny) {
        anketItem.value = anketItem.valueAny.toISOString();
      }
      return true;
    }

    if (controlTypeCode === 80 || controlTypeCode === 90 || controlTypeCode === 100) {
      if (anketItem.valueAny) {
        anketItem.value = anketItem.valueAny ? JSON.stringify(anketItem.valueAny) : null;
      }
      return true;
    }

    if (controlTypeCode === 50 || controlTypeCode === 60) {
      if (anketItem.valueAddOn && anketItem.valueAddOn.dopDateTimeDate) {
        anketItem.valueAddOn.dopDateTime = anketItem.valueAddOn.dopDateTimeDate.toISOString();
      }

      const addOnJsonPar = anketItem.schemeItem.parameters.find(x => x.parameterType.code === 80);
      if (addOnJsonPar) {
        const addOnJson = JSON.parse(addOnJsonPar.value);
        if (addOnJson) {
          if (addOnJson.checks) {
            anketItem.value = JSON.stringify(anketItem.valueAddOn);
            return true;
          }
          if (addOnJson.ctrls) {
            anketItem.value = JSON.stringify(anketItem.valueAny);
            return true;
          }
        }
      }

      if (anketItem.schemeItem.parameters.filter(x => x.parameterType.code === 50).length === 1) {
        anketItem.value = JSON.stringify(anketItem.valueAddOn);
        return true;
      } else if (anketItem.valueAddOn) {
        const aOn = anketItem.valueAddOn;
        if (aOn.dopTextBox || aOn.dopDateTime || aOn.dopDateTimeDate) {
          anketItem.value = JSON.stringify(anketItem.valueAddOn);
          return true;
        }
      }
    }

    if (controlTypeCode === 110) {
      if (anketItem.valueAny) {
        anketItem.value = anketItem.valueAny ? JSON.stringify(anketItem.valueAny) : null;
      }
      return true;
    }

    if (controlTypeCode === 130) {
      if (anketItem.valueAny) {
        anketItem.value = anketItem.valueAny ? JSON.stringify(anketItem.valueAny) : null;
      }
      return true;
    }

    return true;
  }

  public static toggleVariantsSelect(anketItem: AnketItemModel, value: string) {
    const idx = anketItem.valueAddOn.selected.indexOf(value);
    if (anketItem.schemeItem.controlTypeCode === 50) {
      anketItem.valueAddOn.selected = idx > -1 ? [] : [value];
    } else {
      if (idx > -1) {
        anketItem.valueAddOn.selected.splice(idx, 1);
      } else {
        anketItem.valueAddOn.selected.push(value);
      }
    }
  }

  public static toggleChecksVariantsSelect(anketItem: AnketItemModel, value: any) {
    const m = anketItem.valueAddOn.checksModel;

    const itm = m.find(x => x.id === value.id);
    if (!itm) {
      m.push({ id: value.id });
    }

    if (anketItem.schemeItem.controlTypeCode === 50) {
      if (itm.selected) {
        itm.selected = false;
      } else {
        m.forEach(el => {
          el.selected = el.id === value.id;
        });
      }
    } else {
      itm.selected = !itm.selected;
    }
  }
}

