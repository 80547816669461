import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { Observable } from 'rxjs';
import { KladrRegionService } from '../services/kladr/kladr-region.service';
import { KladrDistrictsService } from '../services/kladr/kladr-districts.service';

@Component({
  selector: 'app-encefalit-territory',
  templateUrl: './encefalit-territory.component.html',
  styleUrls: ['./encefalit-territory.component.scss']
})
export class EncefalitTerritoryComponent implements OnInit {
  @ViewChild('comboRegions', { static: false }) public comboRegions: ComboBoxComponent;
  @ViewChild('comboDistricts', { static: false }) public comboDistricts: ComboBoxComponent;

  public viewRegions: Observable<any>;
  public viewDistricts: Observable<any>;

  public virtual: any = {
    itemHeight: 30
  };


  @Input() public disabled: boolean;
  @Input() public disabledDistricts: boolean;
  @Input() public model: any = undefined;
  @Input() public visibleElements = "all";

  constructor(private serviceRegions: KladrRegionService, private serviceDistricts: KladrDistrictsService) {
    this.viewRegions = serviceRegions;
    this.viewDistricts = serviceDistricts;
  }

  ngOnInit() {
    this.disabledDistricts = !this.model.region;
    if (this.model.region && this.model.region.name) {
      this.serviceRegions.query(this.model.region.name);
      if (this.model.district && this.model.district.name) {
        this.serviceDistricts.query(this.model.district.name, this.model.region.id);
      }
    }
  }

  public handleRegionsFilter(value: string) {
    if (value.length >= 1) {
      this.serviceRegions.query(value);
    }
  }

  public handleDistrictsFilter(value: string) {
    if (value.length >= 1 && this.model.region && this.model.region.id) {
      this.serviceDistricts.query(value, this.model.region.id);
    }
  }

  public handleRegionsChange() {
    this.model.district = undefined;
    this.disabledDistricts = !this.model.region;
  }

  hasInVariantsSelect(value: string) {
    return this.model.endem === value;
  }

  varItemClick(value: string) {
    this.model.endem = this.model.endem === value ? undefined : value;
  }
}
