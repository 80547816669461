import { Component, Input, OnInit } from '@angular/core';
import { DateInputMessages } from '@progress/kendo-angular-dateinputs';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { AnketItemModel } from '../anket-item-model';
import { AnketsDataService } from '../data.service';

@Component({
  selector: 'app-ctrl-lechenie-naznach',
  templateUrl: './ctrl-lechenie-naznach.component.html',
  styleUrls: ['./ctrl-lechenie-naznach.component.scss']
})
export class CtrlLechenieNaznachComponent implements OnInit {

  @Input() public disabled: boolean; 
  @Input() public dataItem: any;
  @Input() public allMnn: any;
  @Input() public userId: any;
  @Input() public doctorId: any; 
  @Input() public patientId: any;
  @Input() public anketId: any;
  @Input() public accountId: any;

  public data: any[];
  public dataLKonsul: any[] = [];
  public dataLInstr: any[] = [];
  public dataLLab: any[] = [];

  public dOpened = false;
  public dMessage = '';

  private loadByPages = false;

  public osVariants1 = [ "амбулаторный", "домашний", "постельный", "стационарный", "санаторный" ];

  constructor(
    private dataService: AnketsDataService,
  ) { }

  ngOnInit() {
    this.fillRecsLKonsul();
    this.fillRecsLInstr();
    this.fillRecsLLab();
  }

  hasInVariantsSelect(dataObj: any, value: string): boolean {
    if (!dataObj) {
      dataObj = {};
    }

    if (!dataObj.selected) {
      dataObj.selected = [];
    }

    return dataObj.selected.indexOf(value) > -1;
  }

  varItemClick(dataObj: any, ev) {
    if (this.disabled) {
      return;
    }

    if (!dataObj) {
      dataObj = {};
    }

    if (!dataObj.selected) {
      dataObj.selected = [];
    }

    const value = ev.target.innerText;

    const idx = dataObj.selected.indexOf(value);
    dataObj.selected = idx > -1 ? [] : [value];
  }

  tryJsonParse(val: string): any {
    try {
      return val ? JSON.parse(val) : null;
    } catch (ex) {
      // console.log('tryJsonParse error', val, ex);
      return null;
    }
  }

  fillRecsLKonsul() {
    let url = `wsdoctor/get-categories-services?categoryId=10`;
    this.dataService.getCustomComboboxData(url).subscribe(
      (data) => {
        if (this.loadByPages) {
          this.dataLKonsul = this.dataLKonsul.concat(data.map(x => {
            return { text: x.text, value: x.id.toString(), name: x.name };
          }));
        } else {
          this.dataLKonsul = data.map(x => {
            return { text: x.text, value: x.id.toString(), name: x.name };
          });
        }
      });
  }

  fillRecsLInstr() {
    let url = `wsdoctor/get-categories-services?categoryId=20`;
    this.dataService.getCustomComboboxData(url).subscribe(
      (data) => {
        if (this.loadByPages) {
          this.dataLInstr = this.dataLInstr.concat(data.map(x => {
            return { text: x.text, value: x.id.toString(), name: x.name };
          }));
        } else {
          this.dataLInstr = data.map(x => {
            return { text: x.text, value: x.id.toString(), name: x.name };
          });
        }
      });
  }

  fillRecsLLab() {
    let url = `wsdoctor/get-categories-services?categoryId=30`;
    this.dataService.getCustomComboboxData(url).subscribe(
      (data) => {
        if (this.loadByPages) {
          this.dataLLab = this.dataLLab.concat(data.map(x => {
            return { text: x.text, value: x.id.toString(), name: x.name };
          }));
        } else {
          this.dataLLab = data.map(x => {
            return { text: x.text, value: x.id.toString(), name: x.name };
          });
        }
      });
  }

  sendToRegistr() {
    this.dataService.sendToRegistr({ anketId: this.anketId, userId: this.userId, doctorId: this.doctorId, patientId: this.patientId, data: this.dataItem.anketItem.valueAny }).subscribe((resp) => {
      const m = resp.success && !resp.friendlyMessage ? "Назначения переданы в регистратуру" : resp.friendlyMessage;
      
      if (resp.result)
        this.dataItem.anketItem.valueAny.accountId = resp.result;

      this.dOpen(m);
    });
  }

  plus() {
    if (this.dataItem.anketItem && this.dataItem.anketItem && this.dataItem.anketItem.valueAny && this.dataItem.anketItem.valueAny.plus) {
      this.dataItem.anketItem.valueAny.plus.push({ var1: {}, var2: {}, var3: {}, var4: {}, var5: {}, var6: {} });
    }
  }

  visVrenieDetails() {
    const v = this.dataItem.anketItem && this.dataItem.anketItem && this.dataItem.anketItem.valueAny 
      && this.dataItem.anketItem.valueAny.privychki && this.dataItem.anketItem.valueAny.privychki.selected
      && this.dataItem.anketItem.valueAny.privychki.selected.length === 1
        ? this.dataItem.anketItem.valueAny.privychki.selected[0]
        : '';

    return v === 'есть';
  }

  visKur() {
    const v = this.dataItem.anketItem && this.dataItem.anketItem && this.dataItem.anketItem.valueAny 
      && this.dataItem.anketItem.valueAny.kur && this.dataItem.anketItem.valueAny.kur.selected
      && this.dataItem.anketItem.valueAny.kur.selected.length === 1
        ? this.dataItem.anketItem.valueAny.kur.selected[0]
        : '';

    return v === 'есть';
  }

  visAlco() {
    const v = this.dataItem.anketItem && this.dataItem.anketItem && this.dataItem.anketItem.valueAny 
      && this.dataItem.anketItem.valueAny.alco && this.dataItem.anketItem.valueAny.alco.selected
      && this.dataItem.anketItem.valueAny.alco.selected.length === 1
        ? this.dataItem.anketItem.valueAny.alco.selected[0]
        : '';

    return v === 'есть';
  }

  visNarko() {
    const v = this.dataItem.anketItem && this.dataItem.anketItem && this.dataItem.anketItem.valueAny 
      && this.dataItem.anketItem.valueAny.narko && this.dataItem.anketItem.valueAny.narko.selected
      && this.dataItem.anketItem.valueAny.narko.selected.length === 1
        ? this.dataItem.anketItem.valueAny.narko.selected[0]
        : '';

    return v === 'есть';
  }

  dClose(): void {
    this.dOpened = false;
  }

  dOpen(message: string): void {
    this.dMessage = message;
    this.dOpened = true;
  }

}
