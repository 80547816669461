import { Component, Input, OnInit } from '@angular/core';
import { AnketsDataService } from '../ankets/data.service';

@Component({
  selector: 'app-inner-per',
  templateUrl: './inner-per.component.html',
  styleUrls: ['./inner-per.component.css']
})
export class InnerPerComponent implements OnInit {

  @Input() public cardsNumbers: Array<any>;
  @Input() public disabled: boolean;

  private _model: any[] = [];

  private podrazdeleniya = [];

  @Input() public set model(value: any[]) {
    this._model = value || [{}, {}, {}, {}, {}];
    console.log(this._model);

    this.data = [];
    this._model.forEach((el) => {
      this.data.push({ d: el.d ? new Date(el.d) : undefined, t: el.t });
    });
  }

  public data: any[];

  constructor(
    private anketsDataService: AnketsDataService
  ) { }

  ngOnInit() {
    this.anketsDataService.getPodrazdeleniyaList().subscribe(
      (data) => {
        if (data.length < 1) {
          return;
        }
        this.podrazdeleniya = data;
      });
  }

  nPlus() {
    this.data.push({});
    this.onChange();
  }

  public onChange() {
    const m = this._model;
    m.length = 0;
    this.data.forEach(el => m.push({ d: el.d || null, t: el.t }));
  }

  public test() {
    console.log('test 1', this._model);
    console.log('test 2', this.model);
    console.log('test 3', this.data);
  }

}
