import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CertVaccinService } from './cert-vaccin.service';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { PatientsService } from '../services/ws/patients.service';
import { AnketsDataService } from '../ankets/data.service';

@Component({
  selector: 'app-cert-vaccin',
  templateUrl: './cert-vaccin.component.html',
  styleUrls: ['./cert-vaccin.component.css']
})
export class CertVaccinComponent implements OnInit {

  public editedRowIndex: number;
  public editedItem: any;
  public patientId: number;
  public patient: any;
  public view: Observable<any>;
  public filialName: string;

  constructor(public certVaccinService: CertVaccinService,
    public patientsService: PatientsService,
    public dataService: AnketsDataService,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(
      (queryParam: any) => {
        this.patientId = parseInt(queryParam['patientId'], 10);
        this.view = this.certVaccinService;
        this.certVaccinService.read(this.patientId);

        const doctorId = parseInt(queryParam['doctorId'], 10);

        this.patientsService.getPatient(this.patientId).subscribe(data => this.patient = data);
        this.dataService.getMedStaffFilial(doctorId).subscribe(data => this.filialName = data);
      }
    );

    (<any>window).inner = (<any>window).inner || {};
    (<any>window).inner.publicFunc3 = this.publicFunc3.bind(this);
  }

  publicFunc3(callback) {
    this.ngZone.run(() => callback(this.privateFunc3()));
  }

  privateFunc3() {
    return {
      patient: this.patient,
      items: this.certVaccinService.data
     };
  }

  public addHandler({sender}) {
    console.log(111, this.filialName);
    this.closeEditor(sender);
    sender.addRow({ clinicName: this.filialName });
  }

  public editHandler({sender, rowIndex, dataItem}) {
    this.closeEditor(sender);
    this.editedRowIndex = rowIndex;
    this.editedItem = Object.assign({}, dataItem);
    sender.editRow(rowIndex);
  }

  public cancelHandler({sender, rowIndex}) {
    this.closeEditor(sender, rowIndex);
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.certVaccinService.resetItem(this.editedItem);
    this.editedRowIndex = undefined;
    this.editedItem = undefined;
  }

  public saveHandler({sender, rowIndex, dataItem, isNew}) {
    dataItem.patientId = this.patientId;
    this.certVaccinService.save(dataItem, isNew);
    sender.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.editedItem = undefined;
  }

  public removeHandler({dataItem}) {
    this.certVaccinService.remove(dataItem);
  }

  public getDateString(date) {
    return moment(date).format('DD.MM.YYYY');
  }

}
