import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppConfig } from 'src/shared/app.config';
import { FieldTemplateConsts } from '../consts/field-template-consts';

@Injectable()
export class FieldTemplateService {

  private addOnHeaders: HttpHeaders = new HttpHeaders({
    // 'Authorization': 'Bearer BQDWZC2Y_iSV3rfmAhv-QxDpmhhJbbmgCnspy7HpIZPX5CbJ74D4Xl4aOyXLUL4smF2gZ_V3wiSXLxdLFPY',
    'Content-Type': 'application/json'
  });

  constructor(private http: HttpClient) { }

  getPage(entityId: string) {
    const url = `${AppConfig.resourceApiUrl}/api/field-template/page`;
    const body = {
      filters: [
        { id: FieldTemplateConsts.filterByEntityId, parameters: { value: entityId } }
      ]
    };
    return this.http.post(url, body, { headers: this.addOnHeaders }).pipe(
      map((res: any) => {
        const result = <any[]>res.data;
        result.forEach(element => {
          element.date = new Date(element.date);
        });
        return result;
      })
    );
  }

  save(ent: any) {
    const url = `${AppConfig.resourceApiUrl}/api/field-template/save`;
    return this.http.post(url, {
      id: ent.id,
      entityId: ent.entityId,
      userId: ent.userId,
      data: ent.data,
      date: ent.date,
    }, { headers: this.addOnHeaders }).pipe(
      map(res => {
        const result = <any>res;
        result.date = new Date(result.date);
        return result;
      })
    );
  }

  remove(id: string) {
    const url = `${AppConfig.resourceApiUrl}/api/field-template/delete?id=${id}`;
    return this.http.post(url, null, { headers: this.addOnHeaders }).pipe(
      map(res => {
        const result = <any>res;
        result.date = new Date(result.date);
        return result;
      })
    );
  }
}
