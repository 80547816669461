import { Component, Input, OnInit } from '@angular/core';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { AnketItemModel } from '../anket-item-model';

@Component({
  selector: 'app-ctrl-chuvstvitelnost',
  templateUrl: './ctrl-chuvstvitelnost.component.html',
  styleUrls: ['./ctrl-chuvstvitelnost.component.scss']
})
export class CtrlChuvstvitelnostComponent implements OnInit {

  @Input() public disabled: boolean; 
  @Input() public dataItem: any;
  @Input() public allMnn: any;

  public osVariants1 = [ "без изменений", "гипостезия", "анестезия", "гиперстезия", "парестезия" ];

  constructor() { }

  ngOnInit() {

  }

  hasInVariantsSelect(dataObj: any, value: string): boolean {
    if (!dataObj) {
      dataObj = {};
    }

    if (!dataObj.selected) {
      dataObj.selected = [];
    }

    return dataObj.selected.indexOf(value) > -1;
  }

  varItemClick(dataObj: any, ev) {
    if (this.disabled) {
      return;
    }

    if (!dataObj) {
      dataObj = {};
    }

    if (!dataObj.selected) {
      dataObj.selected = [];
    }

    const value = ev.target.innerText;

    const idx = dataObj.selected.indexOf(value);
    dataObj.selected = idx > -1 ? [] : [value];
  }

  tryJsonParse(val: string): any {
    try {
      return val ? JSON.parse(val) : null;
    } catch (ex) {
      // console.log('tryJsonParse error', val, ex);
      return null;
    }
  }

  plus() {
    if (this.dataItem.anketItem && this.dataItem.anketItem && this.dataItem.anketItem.valueAny && this.dataItem.anketItem.valueAny.plus) {
      this.dataItem.anketItem.valueAny.plus.push({ var1: {}, var2: {}, var3: {}, var4: {}, var5: {}, var6: {} });
    }
  }

  visVrenieDetails() {
    const v = this.dataItem.anketItem && this.dataItem.anketItem && this.dataItem.anketItem.valueAny 
      && this.dataItem.anketItem.valueAny.privychki && this.dataItem.anketItem.valueAny.privychki.selected
      && this.dataItem.anketItem.valueAny.privychki.selected.length === 1
        ? this.dataItem.anketItem.valueAny.privychki.selected[0]
        : '';

    return v === 'есть';
  }

  visKur() {
    const v = this.dataItem.anketItem && this.dataItem.anketItem && this.dataItem.anketItem.valueAny 
      && this.dataItem.anketItem.valueAny.kur && this.dataItem.anketItem.valueAny.kur.selected
      && this.dataItem.anketItem.valueAny.kur.selected.length === 1
        ? this.dataItem.anketItem.valueAny.kur.selected[0]
        : '';

    return v === 'есть';
  }

  visAlco() {
    const v = this.dataItem.anketItem && this.dataItem.anketItem && this.dataItem.anketItem.valueAny 
      && this.dataItem.anketItem.valueAny.alco && this.dataItem.anketItem.valueAny.alco.selected
      && this.dataItem.anketItem.valueAny.alco.selected.length === 1
        ? this.dataItem.anketItem.valueAny.alco.selected[0]
        : '';

    return v === 'есть';
  }

  visNarko() {
    const v = this.dataItem.anketItem && this.dataItem.anketItem && this.dataItem.anketItem.valueAny 
      && this.dataItem.anketItem.valueAny.narko && this.dataItem.anketItem.valueAny.narko.selected
      && this.dataItem.anketItem.valueAny.narko.selected.length === 1
        ? this.dataItem.anketItem.valueAny.narko.selected[0]
        : '';

    return v === 'есть';
  }

}
