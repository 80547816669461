import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';
  constructor(private ngZone: NgZone) {}

  ngOnInit() {
    (<any>window).inner = (<any>window).inner || {};
    (<any>window).inner.publicFunc = this.publicFunc.bind(this);
  }

  ngOnDestroy() {
    (<any>window).inner.publicFunc = null;
  }

  publicFunc() {
    this.ngZone.run(() => this.privateFunc());
  }

  privateFunc() {
    // do private stuff
    console.log('called privateFunc in angular');
  }
}
