import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-medical-history',
  templateUrl: './medical-history.component.html',
  styleUrls: ['./medical-history.component.css']
})
export class MedicalHistoryComponent implements OnInit {

  @Input() public cardsNumbers: Array<any>;
  @Input() public disabled: boolean;
  @Input() public model: any = undefined;

  constructor() { }

  ngOnInit() {
  }

}
