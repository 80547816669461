import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppConfig } from 'src/shared/app.config';

@Injectable()
export class ProceduresService {

  private addOnHeaders: HttpHeaders = new HttpHeaders({
    // 'Authorization': 'Bearer BQDWZC2Y_iSV3rfmAhv-QxDpmhhJbbmgCnspy7HpIZPX5CbJ74D4Xl4aOyXLUL4smF2gZ_V3wiSXLxdLFPY',
    'Content-Type': 'application/json'
  });

  constructor(private http: HttpClient) { }

  getProcedures() {
    const url = `${AppConfig.resourceApiUrl}/api/wsdoctor/get-procedures`;
    return this.http.post(url, { }, { headers: this.addOnHeaders }).pipe(
      map(res => <any[]>res)
    );
  }
}
