import { MessageService } from "@progress/kendo-angular-l10n";

const data = {
  ru: {
    rtl: false,
    messages: {
      "kendo.grid.noRecords": "Нет записей",
      "kendo.dropdownlist.noDataText": "Нет записей",
      "kendo.combobox.noDataText": "Нет записей",
      "kendo.autocomplete.noDataText": "Нет записей",
      "kendo.multiselect.noDataText": "Нет записей",
      "kendo.datetimepicker.dateTab": "Дата",
      "kendo.datetimepicker.dateTabLabel": "Дата",
      "kendo.datetimepicker.timeTab": "Время",
      "kendo.datetimepicker.timeTabLabel": "Время",
      "kendo.datetimepicker.cancelLabel": "Отмена",
      "kendo.datetimepicker.acceptLabel": "Применить",
      "kendo.datetimepicker.nowLabel": "Сейчас",
      "kendo.datepicker.today": "Сегодня",
      "kendo.datepicker.now": "Сейчас",
      "kendo.datepicker.dateTab": "Дата",
      "kendo.datepicker.timeTab": "Время",
      "kendo.datepicker.accept": "Применить",
      "kendo.datepicker.cancel": "Отменить",
    },
  }
};

export class WsDocMessageService extends MessageService {
  public set language(value: string) {
    const lang = data[value];
    if (lang) {
      this.localeId = value;
      this.notify(lang.rtl);
    }
  }

  public get language(): string {
    return this.localeId;
  }

  private localeId = "ru";
  private get messages(): any {
    const lang = data[this.localeId];

    if (lang) {
      return lang.messages;
    }
  }

  public get(key: string): string {
    return this.messages[key];
  }
}