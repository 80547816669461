import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ControlTypeModel } from 'src/app/control-types/control-type-model';
import { ParameterTypesDataService } from 'src/app/parameter-types/data.service';
import { ParameterTypeModel } from 'src/app/parameter-types/parameter-type-model';
import { SchemeItemEditDataService } from './data.service';
import { SchemeItemEditModel } from './scheme-item-edit-model';
import { SchemeItemEditParametersService } from './scheme-item-edit-parameters.service';

const parsCreateFormGroup = (dataItem) =>
    new FormGroup({
      tmpIntId: new FormControl(dataItem.tmpIntId),
      id: new FormControl(dataItem.id),
      schemeItemId: new FormControl(dataItem.schemeItemId, Validators.required),
      parameterTypeId: new FormControl(dataItem.parameterTypeId, Validators.required),
      value: new FormControl(dataItem.value, Validators.required)
    });

const parsMatches = (el, selector) => (el.matches || el.msMatchesSelector).call(el, selector);

@Component({
  selector: 'app-scheme-item-edit',
  templateUrl: './scheme-item-edit.component.html',
  styleUrls: ['./scheme-item-edit.component.css'],
  providers: [SchemeItemEditDataService, SchemeItemEditParametersService, ParameterTypesDataService]
})
export class SchemeItemEditComponent implements OnInit, OnDestroy {
  @ViewChild(GridComponent, {static: false}) private pGrid: GridComponent;

  public controlTypeItems: Array<ControlTypeModel> = [];

  public active = false;
  public itemEditForm: FormGroup = new FormGroup({
      id: new FormControl(),
      schemeId: new FormControl(),
      parentId: new FormControl(),
      schemeItemName: new FormControl('', Validators.required),
      controlTypeId: new FormControl('controlTypeId', Validators.required),
      alias: new FormControl(),
      sort: new FormControl(),
  });

  @Input() public isNew = false;

  @Input() public set model(entity: SchemeItemEditModel) {
      this.itemEditForm.reset(entity);
      if (entity) {
        this.parsService.setItems(entity.parameters || []);
      }
      this.active = entity !== undefined;
  }

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<SchemeItemEditModel> = new EventEmitter();

  public parsView: any[];
  public parsFormGroup: FormGroup;

  private parsEditedRowIndex: number;
  private parsDocClickSubscription: any;
  private parsIsNew = false;

  public parameterTypes: Array<ParameterTypeModel> = [];

  constructor (
    @Inject(SchemeItemEditDataService) private dataService: SchemeItemEditDataService,
    @Inject(SchemeItemEditParametersService) private parsService: SchemeItemEditParametersService,
    @Inject(ParameterTypesDataService) private parameterTypesService: ParameterTypesDataService,
    private renderer: Renderer2
  ) { }

  ngOnDestroy(): void {
    this.parsDocClickSubscription();
  }

  ngOnInit(): void {
    this.dataService.getAllControlTypes().subscribe(
      resp => {
        this.controlTypeItems = resp;
      }
    );

    this.parameterTypesService.getAllParameterTypes().subscribe(
      resp => {
        this.parameterTypes = resp;
      }
    );

    this.parsView = this.parsService.items();
    this.parsDocClickSubscription = this.renderer.listen('document', 'click', this.parsOnDocumentClick.bind(this));
  }

  public onSave(e): void {
      e.preventDefault();

      if (this.parsFormGroup) {
        if (this.parsFormGroup.valid) {
          this.parsSaveCurrent();
        } else {
          return;
        }
      }

      const valueObject: SchemeItemEditModel = this.itemEditForm.value;
      valueObject.parameters = this.parsService.items();

      this.save.emit(valueObject);
      this.active = false;
  }

  public onCancel(e): void {
      e.preventDefault();
      this.closeForm();
  }

  private closeForm(): void {
      this.active = false;
      this.cancel.emit();
  }

  public getParameretType(id: string): any {
    return this.parameterTypes.find(x => x.id === id);
  }

  public parsCellClickHandler({ isEdited, dataItem, rowIndex }): void {
    if (isEdited || (this.parsFormGroup && !this.parsFormGroup.valid)) {
        return;
    }

    if (this.parsIsNew) {
        rowIndex += 1;
    }

    this.parsSaveCurrent();

    this.parsFormGroup = parsCreateFormGroup(dataItem);
    this.parsEditedRowIndex = rowIndex;

    this.pGrid.editRow(rowIndex, this.parsFormGroup);
  }

  public parsAddHandler(): void {
    this.parsCloseEditor();

    this.parsFormGroup = parsCreateFormGroup({
      id: null,
      schemeItemId: (<SchemeItemEditModel>this.itemEditForm.value).id,
      parameterTypeId: null,
      value: ''
    });
    this.parsIsNew = true;

    this.pGrid.addRow(this.parsFormGroup);
  }

  public parsCancelHandler(): void {
    this.parsCloseEditor();
  }

  private parsOnDocumentClick(e: any): void {
    if (
        this.parsFormGroup &&
        this.parsFormGroup.valid &&
        !parsMatches(e.target, '#parsGrid tbody *, #parsGrid .k-grid-toolbar .k-button')
    ) {
        this.parsSaveCurrent();
    }
  }

  private parsCloseEditor(): void {
    if (this.pGrid) {
      this.pGrid.closeRow(this.parsEditedRowIndex);
    }

    this.parsIsNew = false;
    this.parsEditedRowIndex = undefined;
    this.parsFormGroup = undefined;
  }

  private parsSaveCurrent(): void {
    if (this.parsFormGroup) {
        this.parsService.save(this.parsFormGroup.value, this.parsIsNew);
        this.parsCloseEditor();
    }
  }
}
