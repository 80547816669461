import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-doc-spravki-edit',
  templateUrl: './doc-spravki-edit.component.html',
  styleUrls: ['./doc-spravki-edit.component.scss']
})
export class DocSpravkiEditComponent {

  public active = false;
  public editForm: FormGroup = new FormGroup({
      id: new FormControl(),
      date: new FormControl('', Validators.required),
      patientId: new FormControl(),
      doctorId: new FormControl(),
      number: new FormControl('', Validators.required),
      vidana: new FormControl('', Validators.required),
      zakluchenieRecomendacii: new FormControl('', Validators.required),
  });

  @Input() public isNew = false;
  @Input() public staffList = [];

  @Input() public set model(entity: any) {
      this.editForm.reset(entity);
      this.active = entity !== undefined;
  }

  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() print: EventEmitter<any> = new EventEmitter();

  public onPrint() {
    this.print.emit(this.editForm.value);
  }

  public onSave(e): void {
      e.preventDefault();
      this.save.emit(this.editForm.value);
      this.active = false;
  }

  public onCancel(e): void {
      e.preventDefault();
      this.closeForm();
  }

  private closeForm(): void {
      this.active = false;
      this.cancel.emit();
  }
}
