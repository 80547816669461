import { Component, Input, OnInit } from '@angular/core';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { AnketItemModel } from '../anket-item-model';

@Component({
  selector: 'app-ctrl-checkboxes',
  templateUrl: './ctrl-checkboxes.component.html',
  styleUrls: ['./ctrl-checkboxes.component.scss']
})
export class CtrlCheckboxesComponent implements OnInit {

  @Input() public disabled: boolean;
  @Input() public dataItem: any;

  constructor() { }

  ngOnInit() {
  }

  hasInVariantsSelect(anketItem: AnketItemModel, value: string): boolean {
    return anketItem.valueAny.selected.indexOf(value) > -1;
  }

  varItemClick(anketItem, ev) {
    if (this.disabled) {
      return;
    }

    const value = ev.target.innerText;
    const idx = anketItem.valueAny.selected.indexOf(value);
    if (anketItem.schemeItem.controlTypeCode === 50) {
      anketItem.valueAny.selected = idx > -1 ? [] : [value];
    } else {
      if (idx > -1) {
        anketItem.valueAny.selected.splice(idx, 1);
      } else {
        anketItem.valueAny.selected.push(value);
      }
    }
  }

  tryJsonParse(val: string): any {
    try {
      return val ? JSON.parse(val) : null;
    } catch (ex) {
      // console.log('tryJsonParse error', val, ex);
      return null;
    }
  }

  visDop(dop: any): boolean {
    if (dop.condition && dop.condition.type === 'visible') {
      return this.dataItem.anketItem && this.dataItem.anketItem.valueAny
        && this.dataItem.anketItem.valueAny.selected
        && this.dataItem.anketItem.valueAny.selected.indexOf(dop.condition.selected) > -1;
    }

    return true;
  }

}
