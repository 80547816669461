import { Component, OnInit } from '@angular/core';
import { DocSpravkiService } from './doc-spravki.service';
import { State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { AnketsDataService } from '../ankets/data.service';
import { AppConfig } from 'src/shared/app.config';

@Component({
  selector: 'app-doc-spravki',
  templateUrl: './doc-spravki.component.html',
  styleUrls: ['./doc-spravki.component.css']
})
export class DocSpravkiComponent implements OnInit {
  public staffList: Array<any> = [];

  public queryPatientId: number;
  public queryDoctorId: number;

  public view: Observable<any>;
  public gridState: any = {
      sort: [],
      skip: 0,
      take: 100
  };

  public editDataItem: any;
  public isNew: boolean;

  constructor(
    private anketsDataService: AnketsDataService,
    private activatedRoute: ActivatedRoute,
    private dataService: DocSpravkiService,
  ) {

  }

  public ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(
      (queryParam: any) => {
          this.queryPatientId = parseInt(queryParam['patientId'], 10);
          this.queryDoctorId = parseInt(queryParam['doctorId'], 10);
      }
    );

    this.anketsDataService.getStaffList().subscribe(
      (data) => {
        this.staffList = data.map(x => {
          return { text: x.text, value: x.id, name: x.name };
        });
      });

    this.view = this.dataService;
    this.dataService.read(this.queryPatientId);
  }

  public onStateChange(state: State): void {
    this.gridState = state;
    this.dataService.read(this.queryPatientId);
  }

  public addHandler() {
    this.editDataItem = {
      date: moment(new Date(), 'DD.MM.YYYY').toDate(),
      patientId: this.queryPatientId,
      doctorId: this.queryDoctorId
    };
    this.isNew = true;
  }

  public editHandler({dataItem}) {
      this.editDataItem = dataItem;
      this.isNew = false;
  }

  public cancelHandler() {
    this.editDataItem = undefined;
  }

  public saveHandler(entity: any) {
    this.dataService.save(entity, this.isNew);
    this.editDataItem = undefined;
  }

  public printHandler(entity: any) {
    const p = (<any>window).parent;
    p.postMessage({ key: 'print', spravka: entity }, AppConfig.wsDoctorUrl);
  }

  public removeHandler({ dataItem }) {
      this.dataService.remove(dataItem.id);
  }

  public getDateString(date) {
    return moment(date).format('DD.MM.YYYY');
  }
}
