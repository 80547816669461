import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from 'src/shared/app.config';
import { DocReceptyFilterConsts } from '../consts/doc-recepty-filter-consts';

const CREATE_ACTION = 'add';
const UPDATE_ACTION = 'save';
const REMOVE_ACTION = 'delete';

@Injectable()
export class DocReceptyService extends BehaviorSubject<any[]> {
  constructor(private http: HttpClient) {
      super([]);
  }

  private addOnHeaders: HttpHeaders = new HttpHeaders({
    // 'Authorization': 'Bearer BQDWZC2Y_iSV3rfmAhv-QxDpmhhJbbmgCnspy7HpIZPX5CbJ74D4Xl4aOyXLUL4smF2gZ_V3wiSXLxdLFPY',
    'Content-Type': 'application/json'
  });

  private patientId: number;
  private data: any[] = [];

  public read(patientId: number) {
      this.patientId = patientId;

      if (this.data.length) {
          return super.next(this.data);
      }

      this.fetch()
          .subscribe(data => {
              super.next(data);
          });
  }

  public save(data: any, isNew?: boolean) {
      const action = isNew ? CREATE_ACTION : UPDATE_ACTION;

      this.reset();

      if (data.date) {
        data.date = moment(data.date).format('DD.MM.YYYY');
      }

      this.fetch(action, data)
          .subscribe(() => this.read(this.patientId), () => this.read(this.patientId));
  }

  public remove(data: any) {
      this.reset();

      this.fetch(REMOVE_ACTION, data)
          .subscribe(() => this.read(this.patientId), () => this.read(this.patientId));
  }

  public resetItem(dataItem: any) {
      if (!dataItem) {
        return;
      }

      const originalDataItem = this.data.find(item => item.id === dataItem.id);

      Object.assign(originalDataItem, dataItem);

      super.next(this.data);
  }

  private reset() {
      this.data = [];
  }

  private fetch(action: string = '', data?: any): Observable<any> {
    switch (action) {
      case '': {
          const url = `${AppConfig.resourceApiUrl}/api/doc-recepty/page`;
          const body = {
            filters: [
              { id: DocReceptyFilterConsts.filterByPatientId, parameters: { value: this.patientId } }
            ]
          };
          return this.http.post(url, body, { headers: this.addOnHeaders }).pipe(
              map(res => {
                const result = <any>res;
                result.data.forEach(element => {
                  element.date = moment(element.date, 'DD.MM.YYYY').toDate();
                });
                return result;
              })
            );
      }
      case 'add': {
        const url = `${AppConfig.resourceApiUrl}/api/doc-recepty/add`;
        return this.http.post(url, data, { headers: this.addOnHeaders }).pipe(
            map(res => <any>res)
          );
      }
      case 'save': {
        const url = `${AppConfig.resourceApiUrl}/api/doc-recepty/save`;
        return this.http.post(url, data, { headers: this.addOnHeaders }).pipe(
            map(res => <any>res)
          );
      }
      case 'delete': {
        const url = `${AppConfig.resourceApiUrl}/api/doc-recepty/delete?id=${data}`;
        return this.http.post(url, null, { headers: this.addOnHeaders }).pipe(
            map(res => <any>res)
          );
      }
    }
  }
}
