import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const db = this.queryGet('db');

    // hardcode
    // const db = 'ikb2';

    const clonedReq = req.clone({
      headers: req.headers.set('db', db)
    });

    return next.handle(clonedReq);
  }

  queryGet(key: string) {
    let q: string[];
    if (q = (new RegExp('[?&]' + encodeURIComponent(key) + '=([^&]*)')).exec(location.search)) {
        return decodeURIComponent(q[1]);
    }
  }
}
