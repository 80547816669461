import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { SchemeItemsDataService } from 'src/app/schemas/scheme-items/data.service';
import { AnketModel } from '../anket-model';
import { AnketsDataService } from '../data.service';

@Component({
  selector: 'app-ankets-list',
  templateUrl: './ankets-list.component.html',
  styleUrls: ['./ankets-list.component.scss']
})
export class AnketsListComponent implements OnInit {
  public view: Observable<any>;
  public gridState: any = {
    sort: [],
    skip: 0,
    take: 100
  };

  public editDataItem: AnketModel;
  public isNew: boolean;

  public schemeId: string;
  public schemeName: string;

  constructor(
    private anketsDataService: AnketsDataService,
    private schemesDataService: SchemeItemsDataService,
    private activatedRoute: ActivatedRoute,
  ) {

  }

  public ngOnInit(): void {
    const snapshot = this.activatedRoute.snapshot;

    this.schemeId = snapshot.params['schemeId'];

    this.anketsDataService.setCurrentSchemeId(this.schemeId);
    this.schemesDataService.getSchemeName(this.schemeId).subscribe({ next: schemeName => this.schemeName = schemeName });

    this.view = this.anketsDataService;
    this.anketsDataService.read();
  }

  public dataStateChange(state: DataStateChangeEvent): void {
      this.gridState = state;
      this.anketsDataService.read();
  }

  public addHandler() {
    this.editDataItem = new AnketModel();
    this.isNew = true;
  }

  public editHandler({dataItem}) {
      this.editDataItem = dataItem;
      this.isNew = false;
  }

  public cancelHandler() {
    this.editDataItem = undefined;
  }

  public saveHandler(entity: AnketModel) {
    this.anketsDataService.save(entity);
    this.editDataItem = undefined;
  }

  public removeHandler({ dataItem }) {
      this.anketsDataService.remove(dataItem.id);
  }
}
